
export const odtuErkekler = `
<!DOCTYPE html>
<html>
<body>
<table class="table table-bordered table-condensed">
  <tbody>
    <tr>
      <td>T.C. Kimlik No</td>
      <td>Soyadı</td>
      <td>Adı</td>
      <td>Puan</td>
      <td>Yurt</td>
    </tr>
    <tr>
      <td>4****</td>
      <td>A****</td>
      <td>O****</td>
      <td>51</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AC***</td>
      <td>AH***</td>
      <td>67</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AC***</td>
      <td>AR***</td>
      <td>46</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>A****</td>
      <td>Ö****</td>
      <td>78</td>
      <td></td>
    </tr>
    <tr>
      <td>3****</td>
      <td>A****</td>
      <td>E****</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AD***</td>
      <td>İ****</td>
      <td>70</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AD***</td>
      <td>E****</td>
      <td>48</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AH***</td>
      <td>B****</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AK***</td>
      <td>ÇA***</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>A****</td>
      <td>R****</td>
      <td>62</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>AKA**</td>
      <td>MU***</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>A****</td>
      <td>U****</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>AK***</td>
      <td>BE***</td>
      <td>66</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>AKB**</td>
      <td>ME***</td>
      <td>37</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AK***</td>
      <td>G****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>AKC**</td>
      <td>EG***</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>AK***</td>
      <td>KE***</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>2****</td>
      <td>A****</td>
      <td>M****</td>
      <td>57</td>
      <td></td>
    </tr>
    <tr>
      <td>18***</td>
      <td>AK***</td>
      <td>ME***</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AK***</td>
      <td>CE***</td>
      <td>67</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AK***</td>
      <td>ER***</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>AKG**</td>
      <td>MU***</td>
      <td>61</td>
      <td></td>
    </tr>
    <tr>
      <td>13***</td>
      <td>AK***</td>
      <td>BE***</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AK***</td>
      <td>AL***</td>
      <td>56</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>AKI**</td>
      <td>MU***</td>
      <td>35</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AK***</td>
      <td>CA***</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AK***</td>
      <td>AH***</td>
      <td>36</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AK***</td>
      <td>EF***</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AK***</td>
      <td>İ****</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AK***</td>
      <td>BA***</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>AKS**</td>
      <td>EG***</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>11***</td>
      <td>AK***</td>
      <td>MU***</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AK***</td>
      <td>S****</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AK***</td>
      <td>ÇI***</td>
      <td>40</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>AKS**</td>
      <td>ME***</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>11***</td>
      <td>AK***</td>
      <td>KE***</td>
      <td>29</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>A****</td>
      <td>D****</td>
      <td>71</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>AKT**</td>
      <td>MU***</td>
      <td>54</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AK***</td>
      <td>AR***</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AL***</td>
      <td>TÜ***</td>
      <td>77</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ALI**</td>
      <td>TA***</td>
      <td>40</td>
      <td></td>
    </tr>
    <tr>
      <td>12***</td>
      <td>AL***</td>
      <td>AD***</td>
      <td>51</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ALK**</td>
      <td>HA***</td>
      <td>55</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>AL***</td>
      <td>AD***</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AL***</td>
      <td>ER***</td>
      <td>68</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ALT**</td>
      <td>HA***</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AL***</td>
      <td>EV***</td>
      <td>38</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AL***</td>
      <td>EG***</td>
      <td>37</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AL***</td>
      <td>Tİ***</td>
      <td>49</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>A****</td>
      <td>N****</td>
      <td>41</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AL***</td>
      <td>EM***</td>
      <td>42</td>
      <td></td>
    </tr>
    <tr>
      <td>101**</td>
      <td>ALTI*</td>
      <td>ALİ*</td>
      <td>55</td>
      <td></td>
    </tr>
    <tr>
      <td>101**</td>
      <td>ALTI*</td>
      <td>ALİ *</td>
      <td>49</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AL***</td>
      <td>KA***</td>
      <td>43</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AL***</td>
      <td>M****</td>
      <td>48</td>
      <td></td>
    </tr>
    <tr>
      <td>2****</td>
      <td>A****</td>
      <td>H****</td>
      <td>75</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AL***</td>
      <td>KE***</td>
      <td>70</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AL***</td>
      <td>O****</td>
      <td>24</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ALT**</td>
      <td>TA***</td>
      <td>28</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AL***</td>
      <td>C****</td>
      <td>38</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AL***</td>
      <td>Y****</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AL***</td>
      <td>EF***</td>
      <td>41</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AL***</td>
      <td>AH***</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AN***</td>
      <td>A****</td>
      <td>75</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AR***</td>
      <td>EF***</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AR***</td>
      <td>EM***</td>
      <td>86</td>
      <td>4. Yurt</td>
    </tr>
    <tr>
      <td>3****</td>
      <td>A****</td>
      <td>Y****</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>2****</td>
      <td>A****</td>
      <td>A****</td>
      <td>41</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AR***</td>
      <td>G****</td>
      <td>38</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AR***</td>
      <td>YA***</td>
      <td>62</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AR***</td>
      <td>YU***</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AR***</td>
      <td>K****</td>
      <td>80</td>
      <td>6. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AR***</td>
      <td>ME***</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AR***</td>
      <td>MU***</td>
      <td>49</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AR***</td>
      <td>O****</td>
      <td>47</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AS***</td>
      <td>B****</td>
      <td>56</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AS***</td>
      <td>AH***</td>
      <td>32</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AS***</td>
      <td>AR***</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AS***</td>
      <td>C****</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>11***</td>
      <td>AS***</td>
      <td>ME***</td>
      <td>98</td>
      <td>4. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>AS***</td>
      <td>ME***</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>4****</td>
      <td>A****</td>
      <td>M****</td>
      <td>59</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AT***</td>
      <td>A****</td>
      <td>57</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AT***</td>
      <td>BA***</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ATA**</td>
      <td>MUS**</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AT***</td>
      <td>SÜ***</td>
      <td>62</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AT***</td>
      <td>E****</td>
      <td>28</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ATA**</td>
      <td>MUH**</td>
      <td>58</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AT***</td>
      <td>SA***</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AT***</td>
      <td>ME***</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AT***</td>
      <td>T****</td>
      <td>65</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AT***</td>
      <td>BE***</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AV***</td>
      <td>B****</td>
      <td>43</td>
      <td></td>
    </tr>
    <tr>
      <td>3****</td>
      <td>AV***</td>
      <td>K****</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AV***</td>
      <td>M****</td>
      <td>36</td>
      <td></td>
    </tr>
    <tr>
      <td>11***</td>
      <td>AY***</td>
      <td>ME***</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>12***</td>
      <td>AY***</td>
      <td>ME***</td>
      <td>37</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AY***</td>
      <td>AN***</td>
      <td>36</td>
      <td></td>
    </tr>
    <tr>
      <td>11***</td>
      <td>AY***</td>
      <td>AB***</td>
      <td>75</td>
      <td></td>
    </tr>
    <tr>
      <td>12***</td>
      <td>AY***</td>
      <td>AB***</td>
      <td>65</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AY***</td>
      <td>AR***</td>
      <td>58</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AY***</td>
      <td>BU***</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>AY***</td>
      <td>ME***</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>AYD**</td>
      <td>OĞ***</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>AY***</td>
      <td>YA***</td>
      <td>36</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AY***</td>
      <td>K****</td>
      <td>50</td>
      <td></td>
    </tr>
    <tr>
      <td>3****</td>
      <td>AY***</td>
      <td>K****</td>
      <td>77</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AY***</td>
      <td>OS***</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>11***</td>
      <td>AY***</td>
      <td>YA***</td>
      <td>74</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>AYH**</td>
      <td>OĞ***</td>
      <td>38</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AY***</td>
      <td>H****</td>
      <td>77</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AY***</td>
      <td>BA***</td>
      <td>30</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AY***</td>
      <td>E****</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>BA***</td>
      <td>YU***</td>
      <td>77</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>BA***</td>
      <td>ME***</td>
      <td>69</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>BA***</td>
      <td>Bİ***</td>
      <td>49</td>
      <td></td>
    </tr>
    <tr>
      <td>2****</td>
      <td>B****</td>
      <td>O****</td>
      <td>63</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>BA***</td>
      <td>HÜ***</td>
      <td>56</td>
      <td></td>
    </tr>
    <tr>
      <td>2****</td>
      <td>B****</td>
      <td>E****</td>
      <td>28</td>
      <td></td>
    </tr>
    <tr>
      <td>12***</td>
      <td>BA***</td>
      <td>MU***</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>B****</td>
      <td>O****</td>
      <td>50</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>B****</td>
      <td>İ****</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>11***</td>
      <td>BA***</td>
      <td>AH***</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>BAL**</td>
      <td>EM***</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>BA***</td>
      <td>Hİ***</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>BA***</td>
      <td>AY***</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>BAM**</td>
      <td>EM***</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>BA***</td>
      <td>AL***</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>BAŞ**</td>
      <td>AH***</td>
      <td>40</td>
      <td></td>
    </tr>
    <tr>
      <td>12***</td>
      <td>BA***</td>
      <td>YU***</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>B****</td>
      <td>F****</td>
      <td>58</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>BA***</td>
      <td>Yİ***</td>
      <td>29</td>
      <td></td>
    </tr>
    <tr>
      <td>3****</td>
      <td>BA***</td>
      <td>M****</td>
      <td>82</td>
      <td>4. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>B****</td>
      <td>D****</td>
      <td>76</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>B****</td>
      <td>Ö****</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>11***</td>
      <td>BAY**</td>
      <td>MUH**</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>BAY**</td>
      <td>AH***</td>
      <td>76</td>
      <td></td>
    </tr>
    <tr>
      <td>13***</td>
      <td>BA***</td>
      <td>MU***</td>
      <td>69</td>
      <td></td>
    </tr>
    <tr>
      <td>11***</td>
      <td>BAY**</td>
      <td>MUS**</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>11***</td>
      <td>BA***</td>
      <td>YU***</td>
      <td>42</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>BA***</td>
      <td>YA***</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>BA***</td>
      <td>BA***</td>
      <td>38</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>BE***</td>
      <td>ME***</td>
      <td>34</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>B****</td>
      <td>Ş****</td>
      <td>72</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>BE***</td>
      <td>C****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>BE***</td>
      <td>AR***</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>B****</td>
      <td>U****</td>
      <td>67</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>BE***</td>
      <td>AH***</td>
      <td>56</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>BE***</td>
      <td>B****</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>BE***</td>
      <td>MU***</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>12***</td>
      <td>BE***</td>
      <td>ME***</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>3****</td>
      <td>BI***</td>
      <td>M****</td>
      <td>84</td>
      <td>4. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Bİ***</td>
      <td>ME***</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Bİ***</td>
      <td>B****</td>
      <td>59</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Bİ***</td>
      <td>MU***</td>
      <td>60</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Bİ***</td>
      <td>A****</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>B****</td>
      <td>K****</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>3****</td>
      <td>B****</td>
      <td>T****</td>
      <td>62</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>B****</td>
      <td>S****</td>
      <td>40</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>BO***</td>
      <td>A****</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>BOR**</td>
      <td>ME***</td>
      <td>76</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>BO***</td>
      <td>B****</td>
      <td>77</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>BO***</td>
      <td>MU***</td>
      <td>38</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>BOY**</td>
      <td>ME***</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>BO***</td>
      <td>E****</td>
      <td>41</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>BO***</td>
      <td>C****</td>
      <td>75</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>BO***</td>
      <td>H****</td>
      <td>66</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>BU***</td>
      <td>Bİ***</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>BU***</td>
      <td>BE***</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>BÜ***</td>
      <td>E****</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>BÜ***</td>
      <td>M****</td>
      <td>74</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>BÜ***</td>
      <td>H****</td>
      <td>65</td>
      <td></td>
    </tr>
    <tr>
      <td>3****</td>
      <td>BÜ***</td>
      <td>M****</td>
      <td>87</td>
      <td>4. Yurt</td>
    </tr>
    <tr>
      <td>2****</td>
      <td>C****</td>
      <td>Y****</td>
      <td>66</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>C****</td>
      <td>A****</td>
      <td>35</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>C****</td>
      <td>U****</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>C****</td>
      <td>H****</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>CA***</td>
      <td>E****</td>
      <td>200</td>
      <td>6. Yurt</td>
    </tr>
    <tr>
      <td>5****</td>
      <td>C****</td>
      <td>K****</td>
      <td>48</td>
      <td></td>
    </tr>
    <tr>
      <td>2****</td>
      <td>C****</td>
      <td>Ç****</td>
      <td>51</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>CE***</td>
      <td>E****</td>
      <td>80</td>
      <td>6. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>CE***</td>
      <td>M****</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Cİ***</td>
      <td>M****</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>CO***</td>
      <td>BA***</td>
      <td>47</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>CO***</td>
      <td>BE***</td>
      <td>34</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>C****</td>
      <td>K****</td>
      <td>65</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>C****</td>
      <td>T****</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÇA***</td>
      <td>S****</td>
      <td>23</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ÇA***</td>
      <td>AL***</td>
      <td>200</td>
      <td>6. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ç****</td>
      <td>R****</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>11***</td>
      <td>ÇA***</td>
      <td>AL***</td>
      <td>40</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÇA***</td>
      <td>ME***</td>
      <td>37</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÇA***</td>
      <td>E****</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ç****</td>
      <td>F****</td>
      <td>90</td>
      <td>4. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÇA***</td>
      <td>MU***</td>
      <td>78</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ç****</td>
      <td>Ç****</td>
      <td>43</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ç****</td>
      <td>K****</td>
      <td>80</td>
      <td>4. Yurt</td>
    </tr>
    <tr>
      <td>3****</td>
      <td>Ç****</td>
      <td>P****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÇA***</td>
      <td>Y****</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÇE***</td>
      <td>BE***</td>
      <td>35</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÇE***</td>
      <td>AL***</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ÇEL**</td>
      <td>BA***</td>
      <td>52</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÇE***</td>
      <td>EM***</td>
      <td>59</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ÇEL**</td>
      <td>ME***</td>
      <td>85</td>
      <td>4. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÇE***</td>
      <td>MU***</td>
      <td>100</td>
      <td>4. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÇE***</td>
      <td>H****</td>
      <td>71</td>
      <td></td>
    </tr>
    <tr>
      <td>11***</td>
      <td>ÇE***</td>
      <td>ME***</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÇE***</td>
      <td>AR***</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ÇET**</td>
      <td>MER**</td>
      <td>45</td>
      <td></td>
    </tr>
    <tr>
      <td>11***</td>
      <td>ÇE***</td>
      <td>OZ***</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>12***</td>
      <td>ÇE***</td>
      <td>OZ***</td>
      <td>37</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÇE***</td>
      <td>S****</td>
      <td>37</td>
      <td></td>
    </tr>
    <tr>
      <td>2****</td>
      <td>Ç****</td>
      <td>U****</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ÇET**</td>
      <td>BA***</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÇE***</td>
      <td>EG***</td>
      <td>40</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÇE***</td>
      <td>ER***</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ÇET**</td>
      <td>MEH**</td>
      <td>64</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÇE***</td>
      <td>AH***</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÇE***</td>
      <td>D****</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÇI***</td>
      <td>E****</td>
      <td>77</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÇI***</td>
      <td>A****</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÇI***</td>
      <td>Y****</td>
      <td>200</td>
      <td>6. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ç****</td>
      <td>İ****</td>
      <td>60</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Çİ***</td>
      <td>M****</td>
      <td>75</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Çİ***</td>
      <td>O****</td>
      <td>78</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Çİ***</td>
      <td>H****</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Çİ***</td>
      <td>D****</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Çİ***</td>
      <td>S****</td>
      <td>78</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Çİ***</td>
      <td>Ö****</td>
      <td>58</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÇO***</td>
      <td>D****</td>
      <td>66</td>
      <td></td>
    </tr>
    <tr>
      <td>5****</td>
      <td>Ç****</td>
      <td>M****</td>
      <td>37</td>
      <td></td>
    </tr>
    <tr>
      <td>2****</td>
      <td>Ç****</td>
      <td>N****</td>
      <td>50</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÇO***</td>
      <td>Ö****</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÇO***</td>
      <td>B****</td>
      <td>86</td>
      <td>4. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>DA***</td>
      <td>MA***</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>DA***</td>
      <td>İ****</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>DA***</td>
      <td>T****</td>
      <td>42</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>DA***</td>
      <td>H****</td>
      <td>80</td>
      <td>4. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>DA***</td>
      <td>ME***</td>
      <td>68</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>D****</td>
      <td>S****</td>
      <td>53</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>DE***</td>
      <td>Y****</td>
      <td>76</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>DE***</td>
      <td>İ****</td>
      <td>61</td>
      <td></td>
    </tr>
    <tr>
      <td>11***</td>
      <td>DEM**</td>
      <td>ME***</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>DE***</td>
      <td>Ö****</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>DE***</td>
      <td>EY***</td>
      <td>84</td>
      <td>4. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>DE***</td>
      <td>D****</td>
      <td>36</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>DE***</td>
      <td>T****</td>
      <td>50</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>DE***</td>
      <td>A****</td>
      <td>200</td>
      <td>6. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>DEN**</td>
      <td>MUH**</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>105**</td>
      <td>DEN**</td>
      <td>MUS**</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>D****</td>
      <td>Z****</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>104**</td>
      <td>DEN**</td>
      <td>MUS**</td>
      <td>69</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>DE***</td>
      <td>H****</td>
      <td>75</td>
      <td></td>
    </tr>
    <tr>
      <td>11***</td>
      <td>DEV**</td>
      <td>ME***</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>DE***</td>
      <td>ER***</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Dİ***</td>
      <td>Y****</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>2****</td>
      <td>D****</td>
      <td>U****</td>
      <td>38</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Dİ***</td>
      <td>E****</td>
      <td>37</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Dİ***</td>
      <td>M****</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Dİ***</td>
      <td>İ****</td>
      <td>65</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>D****</td>
      <td>B****</td>
      <td>26</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Dİ***</td>
      <td>Ö****</td>
      <td>34</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Dİ***</td>
      <td>O****</td>
      <td>48</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>DO***</td>
      <td>AH***</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>DO***</td>
      <td>D****</td>
      <td>72</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>DO***</td>
      <td>ME***</td>
      <td>66</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>D****</td>
      <td>R****</td>
      <td>34</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>DO***</td>
      <td>AR***</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>DO***</td>
      <td>MU***</td>
      <td>66</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>DO***</td>
      <td>O****</td>
      <td>57</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>DO***</td>
      <td>H****</td>
      <td>78</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>DÖ***</td>
      <td>M****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>D****</td>
      <td>V****</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>DU***</td>
      <td>Y****</td>
      <td>37</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>DU***</td>
      <td>E****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>DU***</td>
      <td>A****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>D****</td>
      <td>N****</td>
      <td>71</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>D****</td>
      <td>G****</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>3****</td>
      <td>E****</td>
      <td>K****</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>E****</td>
      <td>U****</td>
      <td>40</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>EK***</td>
      <td>R****</td>
      <td>78</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>EK***</td>
      <td>E****</td>
      <td>66</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>E****</td>
      <td>H****</td>
      <td>69</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>E****</td>
      <td>C****</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>EL***</td>
      <td>D****</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>2****</td>
      <td>E****</td>
      <td>İ****</td>
      <td>33</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>EL***</td>
      <td>S****</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>EN***</td>
      <td>S****</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ER***</td>
      <td>AH***</td>
      <td>65</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ER***</td>
      <td>EK***</td>
      <td>65</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ER***</td>
      <td>SÜ***</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ER***</td>
      <td>YU***</td>
      <td>29</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ER***</td>
      <td>AT***</td>
      <td>56</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ER***</td>
      <td>B****</td>
      <td>89</td>
      <td>4. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ER***</td>
      <td>ER***</td>
      <td>90</td>
      <td>4. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ER***</td>
      <td>İB***</td>
      <td>29</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ER***</td>
      <td>İL***</td>
      <td>66</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ER***</td>
      <td>YA***</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ERE**</td>
      <td>EM***</td>
      <td>32</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ER***</td>
      <td>SE***</td>
      <td>64</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ER***</td>
      <td>AR***</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ERG**</td>
      <td>EM***</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ER***</td>
      <td>EG***</td>
      <td>43</td>
      <td></td>
    </tr>
    <tr>
      <td>11***</td>
      <td>ER***</td>
      <td>SE***</td>
      <td>79</td>
      <td></td>
    </tr>
    <tr>
      <td>101**</td>
      <td>ERK**</td>
      <td>ENE**</td>
      <td>67</td>
      <td></td>
    </tr>
    <tr>
      <td>106**</td>
      <td>ERK**</td>
      <td>ENE**</td>
      <td>47</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>E****</td>
      <td>O****</td>
      <td>55</td>
      <td></td>
    </tr>
    <tr>
      <td>11***</td>
      <td>ER***</td>
      <td>EN***</td>
      <td>53</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>E****</td>
      <td>K****</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>E****</td>
      <td>T****</td>
      <td>87</td>
      <td>4. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ER***</td>
      <td>D****</td>
      <td>78</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ER***</td>
      <td>ET***</td>
      <td>200</td>
      <td>6. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ERS**</td>
      <td>GÖ***</td>
      <td>200</td>
      <td>6. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ER***</td>
      <td>AB***</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ERT**</td>
      <td>GÖ***</td>
      <td>32</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ES***</td>
      <td>R****</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ES***</td>
      <td>E****</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>E****</td>
      <td>M****</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>11***</td>
      <td>ES***</td>
      <td>SA***</td>
      <td>62</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ES***</td>
      <td>B****</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ES***</td>
      <td>A****</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ES***</td>
      <td>SA***</td>
      <td>99</td>
      <td>4. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>EŞ***</td>
      <td>EF***</td>
      <td>35</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>EŞ***</td>
      <td>EK***</td>
      <td>38</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>EV***</td>
      <td>Y****</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>EZ***</td>
      <td>A****</td>
      <td>91</td>
      <td>4. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>F****</td>
      <td>Y****</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>F****</td>
      <td>M****</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>GE***</td>
      <td>D****</td>
      <td>50</td>
      <td></td>
    </tr>
    <tr>
      <td>3****</td>
      <td>G****</td>
      <td>Y****</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>GE***</td>
      <td>K****</td>
      <td>79</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>GE***</td>
      <td>E****</td>
      <td>41</td>
      <td></td>
    </tr>
    <tr>
      <td>3****</td>
      <td>G****</td>
      <td>O****</td>
      <td>68</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>GE***</td>
      <td>S****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>GE***</td>
      <td>M****</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>GE***</td>
      <td>F****</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>GO***</td>
      <td>A****</td>
      <td>68</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>G****</td>
      <td>N****</td>
      <td>66</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>GÖ***</td>
      <td>AT***</td>
      <td>63</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>GÖ***</td>
      <td>AH***</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>GÖ***</td>
      <td>KA***</td>
      <td>41</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>GÖ***</td>
      <td>KE***</td>
      <td>66</td>
      <td></td>
    </tr>
    <tr>
      <td>5****</td>
      <td>G****</td>
      <td>O****</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>11***</td>
      <td>GÖ***</td>
      <td>KE***</td>
      <td>29</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>GÖ***</td>
      <td>İ****</td>
      <td>56</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>GÖ***</td>
      <td>H****</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>GÖ***</td>
      <td>M****</td>
      <td>51</td>
      <td></td>
    </tr>
    <tr>
      <td>123**</td>
      <td>GÜL**</td>
      <td>ALP**</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>106**</td>
      <td>GÜL**</td>
      <td>MEH**</td>
      <td>40</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>GÜ***</td>
      <td>FA***</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>104**</td>
      <td>GÜL**</td>
      <td>ARD**</td>
      <td>32</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>GÜ***</td>
      <td>AL***</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>124**</td>
      <td>GÜL**</td>
      <td>ALP**</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>106**</td>
      <td>GÜL**</td>
      <td>ARD**</td>
      <td>69</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>GÜ***</td>
      <td>İB***</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>105**</td>
      <td>GÜL**</td>
      <td>MEH**</td>
      <td>49</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>G****</td>
      <td>T****</td>
      <td>70</td>
      <td></td>
    </tr>
    <tr>
      <td>14***</td>
      <td>GÜ***</td>
      <td>AL***</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>GÜ***</td>
      <td>BE***</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>2****</td>
      <td>G****</td>
      <td>E****</td>
      <td>82</td>
      <td>4. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>GÜ***</td>
      <td>FU***</td>
      <td>71</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>GÜ***</td>
      <td>OR***</td>
      <td>74</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>GÜ***</td>
      <td>KA***</td>
      <td>29</td>
      <td></td>
    </tr>
    <tr>
      <td>2****</td>
      <td>G****</td>
      <td>M****</td>
      <td>51</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>G****</td>
      <td>U****</td>
      <td>32</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>GÜ***</td>
      <td>H****</td>
      <td>58</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>GÜ***</td>
      <td>AY***</td>
      <td>33</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>GÜ***</td>
      <td>İS***</td>
      <td>77</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>GÜ***</td>
      <td>YA***</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>GÜ***</td>
      <td>S****</td>
      <td>95</td>
      <td>4. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>GÜ***</td>
      <td>YU***</td>
      <td>29</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>GÜ***</td>
      <td>AH***</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>GÜ***</td>
      <td>D****</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>G****</td>
      <td>C****</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>GÜ***</td>
      <td>OĞ***</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>GÜ***</td>
      <td>ER***</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>GÜ***</td>
      <td>AK***</td>
      <td>80</td>
      <td>6. Yurt</td>
    </tr>
    <tr>
      <td>12***</td>
      <td>GÜ***</td>
      <td>EG***</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>GÜ***</td>
      <td>BA***</td>
      <td>76</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>GÜ***</td>
      <td>EG***</td>
      <td>89</td>
      <td>4. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>GÜ***</td>
      <td>KI***</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>GÜY**</td>
      <td>AR***</td>
      <td>87</td>
      <td>4. Yurt</td>
    </tr>
    <tr>
      <td>14***</td>
      <td>GÜ***</td>
      <td>YU***</td>
      <td>40</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>H****</td>
      <td>Ç****</td>
      <td>46</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>H****</td>
      <td>İ****</td>
      <td>40</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>H****</td>
      <td>C****</td>
      <td>31</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>I****</td>
      <td>E****</td>
      <td>57</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>I****</td>
      <td>M****</td>
      <td>77</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>İÇ***</td>
      <td>E****</td>
      <td>77</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>İL***</td>
      <td>M****</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>İ****</td>
      <td>B****</td>
      <td>37</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>İ****</td>
      <td>U****</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>İ****</td>
      <td>A****</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>5****</td>
      <td>İ****</td>
      <td>M****</td>
      <td>65</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>İN***</td>
      <td>E****</td>
      <td>30</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>İN***</td>
      <td>C****</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>6****</td>
      <td>İ****</td>
      <td>O****</td>
      <td>31</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>İR***</td>
      <td>C****</td>
      <td>55</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>İS***</td>
      <td>M****</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>İ****</td>
      <td>K****</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>İ****</td>
      <td>S****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>3****</td>
      <td>İ****</td>
      <td>B****</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KA***</td>
      <td>C****</td>
      <td>88</td>
      <td>4. Yurt</td>
    </tr>
    <tr>
      <td>11***</td>
      <td>KA***</td>
      <td>KA***</td>
      <td>31</td>
      <td></td>
    </tr>
    <tr>
      <td>17***</td>
      <td>KA***</td>
      <td>ER***</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>KA***</td>
      <td>KA***</td>
      <td>42</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>KAH**</td>
      <td>YU***</td>
      <td>34</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>KAL**</td>
      <td>SA***</td>
      <td>52</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>KAL**</td>
      <td>ÖM***</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>12***</td>
      <td>KA***</td>
      <td>KE***</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>KA***</td>
      <td>ME***</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>KAN**</td>
      <td>YU***</td>
      <td>45</td>
      <td></td>
    </tr>
    <tr>
      <td>11***</td>
      <td>KAP**</td>
      <td>ME***</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>3****</td>
      <td>KA***</td>
      <td>A****</td>
      <td>62</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KA***</td>
      <td>AR***</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>15***</td>
      <td>KA***</td>
      <td>BA***</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>KAR**</td>
      <td>ÖM***</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KA***</td>
      <td>Yİ***</td>
      <td>86</td>
      <td>4. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KA***</td>
      <td>SE***</td>
      <td>42</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>KA***</td>
      <td>KE***</td>
      <td>70</td>
      <td></td>
    </tr>
    <tr>
      <td>118**</td>
      <td>KAR**</td>
      <td>AHM**</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>2****</td>
      <td>KA***</td>
      <td>Y****</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>K****</td>
      <td>N****</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KA***</td>
      <td>OZ***</td>
      <td>44</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KA***</td>
      <td>EN***</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>110**</td>
      <td>KAR**</td>
      <td>AHM**</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>11***</td>
      <td>KA***</td>
      <td>AL***</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KA***</td>
      <td>OĞ***</td>
      <td>58</td>
      <td></td>
    </tr>
    <tr>
      <td>19***</td>
      <td>KA***</td>
      <td>SA***</td>
      <td>63</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KA***</td>
      <td>KU***</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KA***</td>
      <td>ES***</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KA***</td>
      <td>MU***</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>KAR**</td>
      <td>HA***</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KA***</td>
      <td>BO***</td>
      <td>36</td>
      <td></td>
    </tr>
    <tr>
      <td>11***</td>
      <td>KA***</td>
      <td>BE***</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>13***</td>
      <td>KA***</td>
      <td>YU***</td>
      <td>200</td>
      <td>6. Yurt</td>
    </tr>
    <tr>
      <td>115**</td>
      <td>KAR**</td>
      <td>AHM**</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>11***</td>
      <td>KAR**</td>
      <td>ME***</td>
      <td>75</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KA***</td>
      <td>ON***</td>
      <td>49</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KA***</td>
      <td>SÜ***</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KA***</td>
      <td>EM***</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>KAR**</td>
      <td>SA***</td>
      <td>47</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KA***</td>
      <td>İ****</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>6****</td>
      <td>K****</td>
      <td>B****</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>13***</td>
      <td>KA***</td>
      <td>AH***</td>
      <td>27</td>
      <td></td>
    </tr>
    <tr>
      <td>103**</td>
      <td>KAY**</td>
      <td>BER**</td>
      <td>36</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KA***</td>
      <td>DE***</td>
      <td>200</td>
      <td>6. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KA***</td>
      <td>Dİ***</td>
      <td>50</td>
      <td></td>
    </tr>
    <tr>
      <td>11***</td>
      <td>KA***</td>
      <td>ER***</td>
      <td>77</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>KAY**</td>
      <td>HA***</td>
      <td>51</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KA***</td>
      <td>Hİ***</td>
      <td>78</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>KAY**</td>
      <td>YU***</td>
      <td>78</td>
      <td></td>
    </tr>
    <tr>
      <td>100**</td>
      <td>KAY**</td>
      <td>BER**</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>11***</td>
      <td>KA***</td>
      <td>BA***</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>KA***</td>
      <td>AL***</td>
      <td>37</td>
      <td></td>
    </tr>
    <tr>
      <td>3****</td>
      <td>K****</td>
      <td>H****</td>
      <td>200</td>
      <td>6. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KA***</td>
      <td>HÜ***</td>
      <td>78</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KE***</td>
      <td>B****</td>
      <td>84</td>
      <td>4. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KE***</td>
      <td>K****</td>
      <td>30</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KE***</td>
      <td>EG***</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KE***</td>
      <td>İ****</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KE***</td>
      <td>EF***</td>
      <td>41</td>
      <td></td>
    </tr>
    <tr>
      <td>3****</td>
      <td>KI***</td>
      <td>E****</td>
      <td>57</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>KI***</td>
      <td>ME***</td>
      <td>200</td>
      <td>6. Yurt</td>
    </tr>
    <tr>
      <td>15***</td>
      <td>KI***</td>
      <td>MU***</td>
      <td>200</td>
      <td>4. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>KIL**</td>
      <td>MU***</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KI***</td>
      <td>UY***</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KI***</td>
      <td>K****</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KI***</td>
      <td>AR***</td>
      <td>34</td>
      <td></td>
    </tr>
    <tr>
      <td>18***</td>
      <td>KI***</td>
      <td>ME***</td>
      <td>43</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KI***</td>
      <td>SE***</td>
      <td>29</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KI***</td>
      <td>Ç****</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KI***</td>
      <td>E****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>KIR**</td>
      <td>MU***</td>
      <td>200</td>
      <td>6. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KI***</td>
      <td>Y****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KI***</td>
      <td>D****</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KI***</td>
      <td>SÜ***</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KI***</td>
      <td>AB***</td>
      <td>62</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KI***</td>
      <td>UĞ***</td>
      <td>61</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>K****</td>
      <td>Ü****</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>11***</td>
      <td>KOB**</td>
      <td>DO***</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KO***</td>
      <td>SE***</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KO***</td>
      <td>YU***</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KO***</td>
      <td>B****</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>KOC**</td>
      <td>MU***</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KO***</td>
      <td>Yİ***</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KO***</td>
      <td>EF***</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KO***</td>
      <td>AT***</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>KOÇ**</td>
      <td>MUH**</td>
      <td>36</td>
      <td></td>
    </tr>
    <tr>
      <td>3****</td>
      <td>KO***</td>
      <td>E****</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>11***</td>
      <td>KO***</td>
      <td>MU***</td>
      <td>62</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KO***</td>
      <td>İ****</td>
      <td>49</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>KOÇ**</td>
      <td>MUS**</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>KO***</td>
      <td>HA***</td>
      <td>29</td>
      <td></td>
    </tr>
    <tr>
      <td>11***</td>
      <td>KO***</td>
      <td>HA***</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>2****</td>
      <td>K****</td>
      <td>İ****</td>
      <td>63</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KO***</td>
      <td>SU***</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KO***</td>
      <td>EN***</td>
      <td>40</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KO***</td>
      <td>AB***</td>
      <td>80</td>
      <td>6. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KO***</td>
      <td>YE***</td>
      <td>47</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KO***</td>
      <td>AH***</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KO***</td>
      <td>EG***</td>
      <td>62</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KO***</td>
      <td>ER***</td>
      <td>65</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KO***</td>
      <td>AL***</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>11***</td>
      <td>KOY**</td>
      <td>DO***</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>KÖK**</td>
      <td>AR***</td>
      <td>49</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KÖ***</td>
      <td>U****</td>
      <td>33</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KÖ***</td>
      <td>S****</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KÖ***</td>
      <td>E****</td>
      <td>50</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KÖ***</td>
      <td>O****</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KÖ***</td>
      <td>K****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>K****</td>
      <td>V****</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>3****</td>
      <td>K****</td>
      <td>Ü****</td>
      <td>62</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>KÖY**</td>
      <td>AR***</td>
      <td>30</td>
      <td></td>
    </tr>
    <tr>
      <td>2****</td>
      <td>KU***</td>
      <td>Y****</td>
      <td>18</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>KU***</td>
      <td>CE***</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KU***</td>
      <td>EG***</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>3****</td>
      <td>KU***</td>
      <td>E****</td>
      <td>61</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KU***</td>
      <td>S****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>12***</td>
      <td>KU***</td>
      <td>CE***</td>
      <td>82</td>
      <td>4. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>K****</td>
      <td>G****</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KU***</td>
      <td>A****</td>
      <td>40</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KU***</td>
      <td>ER***</td>
      <td>42</td>
      <td></td>
    </tr>
    <tr>
      <td>2****</td>
      <td>K****</td>
      <td>B****</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KÜ***</td>
      <td>E****</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KÜ***</td>
      <td>H****</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KÜ***</td>
      <td>Ö****</td>
      <td>51</td>
      <td></td>
    </tr>
    <tr>
      <td>3****</td>
      <td>KÜ***</td>
      <td>A****</td>
      <td>58</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KÜ***</td>
      <td>Ç****</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>L****</td>
      <td>O****</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>M****</td>
      <td>O****</td>
      <td>38</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>MA***</td>
      <td>ME***</td>
      <td>56</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>MAR**</td>
      <td>MU***</td>
      <td>49</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>MAT**</td>
      <td>MU***</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ME***</td>
      <td>F****</td>
      <td>79</td>
      <td></td>
    </tr>
    <tr>
      <td>2****</td>
      <td>M****</td>
      <td>T****</td>
      <td>32</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>M****</td>
      <td>E****</td>
      <td>71</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>MU***</td>
      <td>M****</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>MU***</td>
      <td>FA***</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>MU***</td>
      <td>FU***</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>M****</td>
      <td>H****</td>
      <td>43</td>
      <td></td>
    </tr>
    <tr>
      <td>3****</td>
      <td>M****</td>
      <td>M****</td>
      <td>76</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>M****</td>
      <td>Y****</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>N****</td>
      <td>Ö****</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>N****</td>
      <td>S****</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>NE***</td>
      <td>M****</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>NO***</td>
      <td>M****</td>
      <td>25</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>OĞ***</td>
      <td>M****</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>3****</td>
      <td>O****</td>
      <td>H****</td>
      <td>53</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>OK***</td>
      <td>M****</td>
      <td>71</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>OK***</td>
      <td>Y****</td>
      <td>52</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>O****</td>
      <td>Ö****</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>O****</td>
      <td>U****</td>
      <td>37</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ON***</td>
      <td>Y****</td>
      <td>73</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>O****</td>
      <td>B****</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>O****</td>
      <td>A****</td>
      <td>25</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÖN***</td>
      <td>G****</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÖN***</td>
      <td>B****</td>
      <td>56</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ö****</td>
      <td>E****</td>
      <td>72</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÖN***</td>
      <td>M****</td>
      <td>56</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ö****</td>
      <td>Ö****</td>
      <td>68</td>
      <td></td>
    </tr>
    <tr>
      <td>12***</td>
      <td>ÖZ**</td>
      <td>AH***</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ö****</td>
      <td>U****</td>
      <td>72</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ÖZA**</td>
      <td>ME***</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ö****</td>
      <td>N****</td>
      <td>38</td>
      <td></td>
    </tr>
    <tr>
      <td>11***</td>
      <td>ÖZ***</td>
      <td>AH***</td>
      <td>71</td>
      <td></td>
    </tr>
    <tr>
      <td>3****</td>
      <td>Ö****</td>
      <td>A****</td>
      <td>37</td>
      <td></td>
    </tr>
    <tr>
      <td>13***</td>
      <td>ÖZ***</td>
      <td>ME***</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>11***</td>
      <td>ÖZ***</td>
      <td>ME***</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>12***</td>
      <td>ÖZ***</td>
      <td>ME***</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>12***</td>
      <td>ÖZC**</td>
      <td>AH***</td>
      <td>77</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ÖZC**</td>
      <td>ME***</td>
      <td>41</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ÖZD**</td>
      <td>BA***</td>
      <td>90</td>
      <td>4. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ö****</td>
      <td>Ç****</td>
      <td>27</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ö****</td>
      <td>D****</td>
      <td>67</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÖZ***</td>
      <td>G****</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÖZ***</td>
      <td>KU***</td>
      <td>73</td>
      <td></td>
    </tr>
    <tr>
      <td>1083*</td>
      <td>ÖZDE*</td>
      <td>MEHM*</td>
      <td>49</td>
      <td></td>
    </tr>
    <tr>
      <td>1085*</td>
      <td>ÖZDE*</td>
      <td>MEHM*</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÖZ***</td>
      <td>MU***</td>
      <td>28</td>
      <td></td>
    </tr>
    <tr>
      <td>13***</td>
      <td>ÖZ***</td>
      <td>SA***</td>
      <td>57</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ÖZD**</td>
      <td>SA***</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>15***</td>
      <td>ÖZ***</td>
      <td>YA***</td>
      <td>43</td>
      <td></td>
    </tr>
    <tr>
      <td>11***</td>
      <td>ÖZ***</td>
      <td>YU***</td>
      <td>90</td>
      <td>4. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÖZ***</td>
      <td>AN***</td>
      <td>36</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ÖZG**</td>
      <td>BA***</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>2****</td>
      <td>Ö****</td>
      <td>A****</td>
      <td>200</td>
      <td>4. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÖZ***</td>
      <td>Yİ***</td>
      <td>55</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÖZ***</td>
      <td>AL***</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ÖZK**</td>
      <td>SA***</td>
      <td>90</td>
      <td>4. Yurt</td>
    </tr>
    <tr>
      <td>11***</td>
      <td>ÖZ***</td>
      <td>YA***</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>5****</td>
      <td>Ö****</td>
      <td>O****</td>
      <td>64</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ÖZ***</td>
      <td>YA***</td>
      <td>34</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÖZ***</td>
      <td>KO***</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ÖZT**</td>
      <td>AR***</td>
      <td>98</td>
      <td>4. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ÖZT**</td>
      <td>ME***</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÖZ***</td>
      <td>BE***</td>
      <td>54</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ö****</td>
      <td>F****</td>
      <td>49</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÖZ***</td>
      <td>KA***</td>
      <td>78</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ö****</td>
      <td>T****</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>15***</td>
      <td>ÖZ***</td>
      <td>YU***</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>16***</td>
      <td>ÖZ***</td>
      <td>YU***</td>
      <td>59</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ÖZY**</td>
      <td>AR***</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>PA***</td>
      <td>F****</td>
      <td>200</td>
      <td>6. Yurt</td>
    </tr>
    <tr>
      <td>2****</td>
      <td>P****</td>
      <td>İ****</td>
      <td>200</td>
      <td>4. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>PA***</td>
      <td>B****</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>PA***</td>
      <td>EM***</td>
      <td>89</td>
      <td>4. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>PA***</td>
      <td>ER***</td>
      <td>37</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>PE***</td>
      <td>FU***</td>
      <td>54</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>P****</td>
      <td>Ö****</td>
      <td>82</td>
      <td>4. Yurt</td>
    </tr>
    <tr>
      <td>12***</td>
      <td>PE***</td>
      <td>FU***</td>
      <td>46</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>P****</td>
      <td>M****</td>
      <td>72</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>P****</td>
      <td>Y****</td>
      <td>49</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>PU***</td>
      <td>B****</td>
      <td>60</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>R****</td>
      <td>A****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>R****</td>
      <td>M****</td>
      <td>69</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>SA***</td>
      <td>EM***</td>
      <td>53</td>
      <td></td>
    </tr>
    <tr>
      <td>3****</td>
      <td>S****</td>
      <td>B****</td>
      <td>68</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>SA***</td>
      <td>ME***</td>
      <td>53</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>SA***</td>
      <td>AR***</td>
      <td>38</td>
      <td></td>
    </tr>
    <tr>
      <td>3****</td>
      <td>S****</td>
      <td>A****</td>
      <td>68</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>SA***</td>
      <td>İ****</td>
      <td>50</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>SA***</td>
      <td>ER***</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>2****</td>
      <td>S****</td>
      <td>K****</td>
      <td>72</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>SA***</td>
      <td>S****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>11***</td>
      <td>SA***</td>
      <td>ME***</td>
      <td>57</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>SA***</td>
      <td>EF***</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>SA***</td>
      <td>AD***</td>
      <td>200</td>
      <td>6. Yurt</td>
    </tr>
    <tr>
      <td>11***</td>
      <td>SE***</td>
      <td>MU***</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>SE***</td>
      <td>İ****</td>
      <td>23</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>SE***</td>
      <td>AR***</td>
      <td>38</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>SE***</td>
      <td>U****</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>12***</td>
      <td>SE***</td>
      <td>BA***</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>SE***</td>
      <td>SE***</td>
      <td>47</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>SE***</td>
      <td>BA***</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>SE***</td>
      <td>HA***</td>
      <td>30</td>
      <td></td>
    </tr>
    <tr>
      <td>13***</td>
      <td>SE***</td>
      <td>SE***</td>
      <td>69</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>SE***</td>
      <td>SO***</td>
      <td>79</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>S****</td>
      <td>Y****</td>
      <td>35</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>S****</td>
      <td>Ö****</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>SE***</td>
      <td>MU***</td>
      <td>37</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>SE***</td>
      <td>AH***</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>S****</td>
      <td>Ç****</td>
      <td>67</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>SE***</td>
      <td>HÜ***</td>
      <td>34</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>SI***</td>
      <td>U****</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>S****</td>
      <td>D****</td>
      <td>50</td>
      <td></td>
    </tr>
    <tr>
      <td>105**</td>
      <td>SOYL*</td>
      <td>KER**</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>105**</td>
      <td>SOYÖ*</td>
      <td>KER**</td>
      <td>67</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>SO***</td>
      <td>KA***</td>
      <td>74</td>
      <td></td>
    </tr>
    <tr>
      <td>2****</td>
      <td>S****</td>
      <td>A****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>SÖ***</td>
      <td>K****</td>
      <td>38</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>SÖ***</td>
      <td>M****</td>
      <td>80</td>
      <td>6. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>SU***</td>
      <td>A****</td>
      <td>62</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>SÜ***</td>
      <td>E****</td>
      <td>37</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ŞA***</td>
      <td>ME***</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ŞAF**</td>
      <td>YU***</td>
      <td>67</td>
      <td></td>
    </tr>
    <tr>
      <td>113**</td>
      <td>ŞAH**</td>
      <td>YUS**</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ş****</td>
      <td>S****</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ŞA***</td>
      <td>B****</td>
      <td>52</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ş****</td>
      <td>E****</td>
      <td>41</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ŞA***</td>
      <td>F****</td>
      <td>29</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ŞA***</td>
      <td>HA***</td>
      <td>88</td>
      <td>4. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ŞA***</td>
      <td>İS***</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>2****</td>
      <td>Ş****</td>
      <td>K****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ŞA***</td>
      <td>O****</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ŞAH**</td>
      <td>YU***</td>
      <td>50</td>
      <td></td>
    </tr>
    <tr>
      <td>118**</td>
      <td>ŞAH**</td>
      <td>YUS**</td>
      <td>30</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ŞA***</td>
      <td>A****</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ŞA***</td>
      <td>HÜ***</td>
      <td>47</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ŞA***</td>
      <td>MU***</td>
      <td>67</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ŞA***</td>
      <td>İB***</td>
      <td>59</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ŞAY**</td>
      <td>YU***</td>
      <td>72</td>
      <td></td>
    </tr>
    <tr>
      <td>12***</td>
      <td>ŞE***</td>
      <td>BA***</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ş****</td>
      <td>U****</td>
      <td>28</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ŞE***</td>
      <td>Y****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ŞE***</td>
      <td>F****</td>
      <td>43</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ŞE***</td>
      <td>A****</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ŞE***</td>
      <td>K****</td>
      <td>31</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ş****</td>
      <td>T****</td>
      <td>28</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ŞE***</td>
      <td>BA***</td>
      <td>49</td>
      <td></td>
    </tr>
    <tr>
      <td>3****</td>
      <td>Ş****</td>
      <td>M****</td>
      <td>60</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Şİ***</td>
      <td>O****</td>
      <td>43</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Şİ***</td>
      <td>K****</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TA***</td>
      <td>UF***</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>11***</td>
      <td>TAB**</td>
      <td>ER***</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TA***</td>
      <td>YU***</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>TAĞ**</td>
      <td>EM***</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TA***</td>
      <td>Yİ***</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TA***</td>
      <td>SE***</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TA***</td>
      <td>O****</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>4****</td>
      <td>T****</td>
      <td>Y****</td>
      <td>42</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TA***</td>
      <td>H****</td>
      <td>52</td>
      <td></td>
    </tr>
    <tr>
      <td>3****</td>
      <td>T****</td>
      <td>C****</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>3****</td>
      <td>T****</td>
      <td>M****</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>11***</td>
      <td>TAŞ**</td>
      <td>ER***</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>TA***</td>
      <td>ME***</td>
      <td>47</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>TA***</td>
      <td>ER***</td>
      <td>87</td>
      <td>4. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TA***</td>
      <td>A****</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>T****</td>
      <td>C****</td>
      <td>26</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TA***</td>
      <td>SÜ***</td>
      <td>72</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TA***</td>
      <td>D****</td>
      <td>40</td>
      <td></td>
    </tr>
    <tr>
      <td>11***</td>
      <td>TA***</td>
      <td>ME***</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>TAŞ**</td>
      <td>EM***</td>
      <td>50</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TA***</td>
      <td>B****</td>
      <td>49</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TA***</td>
      <td>UL***</td>
      <td>200</td>
      <td>6. Yurt</td>
    </tr>
    <tr>
      <td bgcolor="yellow">
        <b>1****</b>
      </td>
      <td bgcolor="yellow">
        <b>T****</b>
      </td>
      <td bgcolor="yellow">
        <b>P****</b>
      </td>
      <td bgcolor="yellow">
        <b>55</b>
      </td>
      <td bgcolor="yellow">
        <b></b>
      </td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TA***</td>
      <td>SA***</td>
      <td>57</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TA***</td>
      <td>MU***</td>
      <td>34</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>T****</td>
      <td>Z****</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TE***</td>
      <td>BE***</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>T****</td>
      <td>G****</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>12***</td>
      <td>TEK**</td>
      <td>MU***</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TE***</td>
      <td>DO***</td>
      <td>30</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>TE***</td>
      <td>MU***</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>12***</td>
      <td>TEM**</td>
      <td>MU***</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TE***</td>
      <td>DE***</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TE***</td>
      <td>E****</td>
      <td>49</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TE***</td>
      <td>K****</td>
      <td>20</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TE***</td>
      <td>AR***</td>
      <td>85</td>
      <td>4. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TE***</td>
      <td>BA***</td>
      <td>64</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TE***</td>
      <td>AH***</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TI***</td>
      <td>A****</td>
      <td>30</td>
      <td></td>
    </tr>
    <tr>
      <td>3****</td>
      <td>T****</td>
      <td>H****</td>
      <td>83</td>
      <td>4. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TO***</td>
      <td>UT***</td>
      <td>42</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TO***</td>
      <td>T****</td>
      <td>76</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TO***</td>
      <td>B****</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TO***</td>
      <td>EM***</td>
      <td>37</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>TO***</td>
      <td>KU***</td>
      <td>38</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TO***</td>
      <td>F****</td>
      <td>54</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TO***</td>
      <td>S****</td>
      <td>86</td>
      <td>4. Yurt</td>
    </tr>
    <tr>
      <td>11***</td>
      <td>TO***</td>
      <td>KU***</td>
      <td>40</td>
      <td></td>
    </tr>
    <tr>
      <td>2****</td>
      <td>T****</td>
      <td>E****</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>3****</td>
      <td>T****</td>
      <td>İ****</td>
      <td>67</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TO***</td>
      <td>D****</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TO***</td>
      <td>UM***</td>
      <td>60</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TO***</td>
      <td>Y****</td>
      <td>38</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TO***</td>
      <td>EF***</td>
      <td>31</td>
      <td></td>
    </tr>
    <tr>
      <td>2****</td>
      <td>T****</td>
      <td>Y****</td>
      <td>62</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TU***</td>
      <td>K****</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TU***</td>
      <td>Y****</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TU***</td>
      <td>BO***</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TU***</td>
      <td>O****</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TU***</td>
      <td>A****</td>
      <td>82</td>
      <td>4. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TU***</td>
      <td>S****</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TU***</td>
      <td>BA***</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TÜ***</td>
      <td>F****</td>
      <td>68</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TÜ***</td>
      <td>T****</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>T****</td>
      <td>Ö****</td>
      <td>72</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TÜ***</td>
      <td>Y****</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TÜ***</td>
      <td>H****</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TÜ***</td>
      <td>AL***</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TÜ***</td>
      <td>D****</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TÜ***</td>
      <td>AT***</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>T****</td>
      <td>İ****</td>
      <td>75</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TÜ***</td>
      <td>AG***</td>
      <td>27</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>U****</td>
      <td>K****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>U****</td>
      <td>O****</td>
      <td>200</td>
      <td>6. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>UÇ***</td>
      <td>A****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>UĞ***</td>
      <td>H****</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>U****</td>
      <td>S****</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>UL***</td>
      <td>EN***</td>
      <td>35</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>UL***</td>
      <td>ES***</td>
      <td>58</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>UL***</td>
      <td>M****</td>
      <td>50</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>UN***</td>
      <td>A****</td>
      <td>40</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>UR***</td>
      <td>M****</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>US***</td>
      <td>A****</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>U****</td>
      <td>B****</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>U****</td>
      <td>G****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>UZ***</td>
      <td>E****</td>
      <td>78</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>UZ***</td>
      <td>A****</td>
      <td>55</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>UZ***</td>
      <td>M****</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>UZ***</td>
      <td>H****</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ü****</td>
      <td>M****</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>3****</td>
      <td>Ü****</td>
      <td>H****</td>
      <td>61</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ü****</td>
      <td>E****</td>
      <td>40</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ü****</td>
      <td>İ****</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÜN***</td>
      <td>A****</td>
      <td>40</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ü****</td>
      <td>O****</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ü****</td>
      <td>B****</td>
      <td>65</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÜS***</td>
      <td>A****</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ü****</td>
      <td>P****</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ü****</td>
      <td>N****</td>
      <td>97</td>
      <td>4. Yurt</td>
    </tr>
    <tr>
      <td>2****</td>
      <td>Y****</td>
      <td>A****</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YA***</td>
      <td>BA***</td>
      <td>48</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>YAĞ**</td>
      <td>MU***</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YA***</td>
      <td>EG***</td>
      <td>49</td>
      <td></td>
    </tr>
    <tr>
      <td>3****</td>
      <td>Y****</td>
      <td>F****</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>11***</td>
      <td>YAL**</td>
      <td>AL***</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YA***</td>
      <td>BE***</td>
      <td>33</td>
      <td></td>
    </tr>
    <tr>
      <td>11***</td>
      <td>YAL**</td>
      <td>MU***</td>
      <td>68</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YA***</td>
      <td>AY***</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>11***</td>
      <td>YAN**</td>
      <td>AL***</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>11***</td>
      <td>YAN**</td>
      <td>MU***</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YA***</td>
      <td>AD***</td>
      <td>75</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YA***</td>
      <td>AN***</td>
      <td>44</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YA***</td>
      <td>S****</td>
      <td>73</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>YA***</td>
      <td>EM***</td>
      <td>28</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YA***</td>
      <td>Ö****</td>
      <td>65</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YA***</td>
      <td>AH***</td>
      <td>63</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YA***</td>
      <td>AR***</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>11***</td>
      <td>YA***</td>
      <td>EM***</td>
      <td>88</td>
      <td>4. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YA***</td>
      <td>ME***</td>
      <td>51</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>YAY**</td>
      <td>MU***</td>
      <td>79</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YA***</td>
      <td>EF***</td>
      <td>48</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YA***</td>
      <td>Y****</td>
      <td>46</td>
      <td></td>
    </tr>
    <tr>
      <td>4****</td>
      <td>Y****</td>
      <td>M****</td>
      <td>84</td>
      <td>4. Yurt</td>
    </tr>
    <tr>
      <td>2****</td>
      <td>Y****</td>
      <td>M****</td>
      <td>91</td>
      <td>4. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YE***</td>
      <td>T****</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YE***</td>
      <td>Ö****</td>
      <td>67</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YE***</td>
      <td>ME***</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YE***</td>
      <td>O****</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YE***</td>
      <td>MU***</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YE***</td>
      <td>E****</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Y****</td>
      <td>C****</td>
      <td>79</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YE***</td>
      <td>A****</td>
      <td>77</td>
      <td></td>
    </tr>
    <tr>
      <td>102**</td>
      <td>YIL**</td>
      <td>AHM**</td>
      <td>44</td>
      <td></td>
    </tr>
    <tr>
      <td>103**</td>
      <td>YIL**</td>
      <td>AHM**</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>3****</td>
      <td>Y****</td>
      <td>E****</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Y****</td>
      <td>F****</td>
      <td>50</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YI***</td>
      <td>HA***</td>
      <td>44</td>
      <td></td>
    </tr>
    <tr>
      <td>105**</td>
      <td>YIL**</td>
      <td>AHM**</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YI***</td>
      <td>AR***</td>
      <td>36</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YI***</td>
      <td>B****</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YI***</td>
      <td>HU***</td>
      <td>79</td>
      <td></td>
    </tr>
    <tr>
      <td>12***</td>
      <td>YI***</td>
      <td>ME***</td>
      <td>200</td>
      <td>2. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YI***</td>
      <td>YA***</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YI***</td>
      <td>EM***</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YI***</td>
      <td>ER***</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>11***</td>
      <td>YI***</td>
      <td>ME***</td>
      <td>40</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>YI***</td>
      <td>MU***</td>
      <td>44</td>
      <td></td>
    </tr>
    <tr>
      <td>12***</td>
      <td>YI***</td>
      <td>MU***</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YI***</td>
      <td>Ö****</td>
      <td>49</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YI***</td>
      <td>S****</td>
      <td>49</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YI***</td>
      <td>T****</td>
      <td>40</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YI***</td>
      <td>YU***</td>
      <td>55</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Yİ***</td>
      <td>B****</td>
      <td>80</td>
      <td>6. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YU***</td>
      <td>S****</td>
      <td>70</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YU***</td>
      <td>M****</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YU***</td>
      <td>Ö****</td>
      <td>61</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YU***</td>
      <td>A****</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Y****</td>
      <td>U****</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YÜ***</td>
      <td>E****</td>
      <td>76</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YÜ***</td>
      <td>O****</td>
      <td>200</td>
      <td>9. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YÜ***</td>
      <td>B****</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Z****</td>
      <td>E****</td>
      <td>200</td>
      <td>8. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Z****</td>
      <td>İ****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Z****</td>
      <td>M****</td>
      <td>40</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Z****</td>
      <td>H****</td>
      <td>72</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>DO***</td>
      <td>Ö****</td>
      <td>200</td>
      <td>İsa Demiray</td>
    </tr>
  </tbody>
</table>
  </body>
  </html>
   
`;

export const odtuKizlar = `
<!DOCTYPE html>
<html>
<body>
<table class="table table-bordered table-condensed">
  <tbody>
    <tr>
      <td>T.C. Kimlik No</td>
      <td>Soyadı</td>
      <td>Adı</td>
      <td>Puan</td>
      <td>Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AC***</td>
      <td>E****</td>
      <td>50</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>A****</td>
      <td>Ö****</td>
      <td>27</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AÇ***</td>
      <td>E****</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AD***</td>
      <td>D****</td>
      <td>36</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AĞ***</td>
      <td>E****</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>AK**</td>
      <td>EL***</td>
      <td>82</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AK***</td>
      <td>DO***</td>
      <td>43</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AK***</td>
      <td>CA***</td>
      <td>61</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AK***</td>
      <td>NA***</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>AKB**</td>
      <td>EL***</td>
      <td>75</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AK***</td>
      <td>ES***</td>
      <td>47</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AK***</td>
      <td>AS***</td>
      <td>37</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AK***</td>
      <td>CE***</td>
      <td>56</td>
      <td>7. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AK***</td>
      <td>M****</td>
      <td>70</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AK***</td>
      <td>I****</td>
      <td>54</td>
      <td>7. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AK***</td>
      <td>NE***</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AK***</td>
      <td>DE***</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AK***</td>
      <td>G****</td>
      <td>55</td>
      <td>7. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AK***</td>
      <td>Z****</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>2****</td>
      <td>A****</td>
      <td>G****</td>
      <td>79</td>
      <td>Faika Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AK***</td>
      <td>AY***</td>
      <td>69</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>3****</td>
      <td>A****</td>
      <td>E****</td>
      <td>57</td>
      <td>7. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AL***</td>
      <td>EL***</td>
      <td>32</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AL***</td>
      <td>H****</td>
      <td>37</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ALB**</td>
      <td>SE***</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>2****</td>
      <td>A****</td>
      <td>D****</td>
      <td>31</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AL***</td>
      <td>M****</td>
      <td>63</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>3****</td>
      <td>A****</td>
      <td>Z****</td>
      <td>63</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ALT**</td>
      <td>SE***</td>
      <td>200</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AL***</td>
      <td>EM***</td>
      <td>77</td>
      <td>Faika Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AN***</td>
      <td>A****</td>
      <td>45</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>A****</td>
      <td>F****</td>
      <td>81</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AR***</td>
      <td>D****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AR***</td>
      <td>Z****</td>
      <td>75</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AR***</td>
      <td>B****</td>
      <td>56</td>
      <td>7. Yurt</td>
    </tr>
    <tr>
      <td>2****</td>
      <td>A****</td>
      <td>A****</td>
      <td>71</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>2****</td>
      <td>A****</td>
      <td>E****</td>
      <td>58</td>
      <td>7. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AR***</td>
      <td>I****</td>
      <td>77</td>
      <td>Faika Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AR***</td>
      <td>N****</td>
      <td>66</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>AS***</td>
      <td>ZE***</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AS***</td>
      <td>EC***</td>
      <td>41</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AS***</td>
      <td>EK***</td>
      <td>49</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AS***</td>
      <td>H****</td>
      <td>72</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>11***</td>
      <td>AS***</td>
      <td>ZE***</td>
      <td>23</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AS***</td>
      <td>D****</td>
      <td>76</td>
      <td>Faika Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AT***</td>
      <td>S****</td>
      <td>76</td>
      <td>Faika Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AT***</td>
      <td>A****</td>
      <td>200</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AT***</td>
      <td>BE***</td>
      <td>97</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>A****</td>
      <td>R****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AT***</td>
      <td>Ş****</td>
      <td>61</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ATE**</td>
      <td>NA***</td>
      <td>52</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>A****</td>
      <td>T****</td>
      <td>48</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ATİ**</td>
      <td>NA***</td>
      <td>49</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AT***</td>
      <td>İL***</td>
      <td>66</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AT***</td>
      <td>BA***</td>
      <td>27</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AT***</td>
      <td>İK***</td>
      <td>81</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AV***</td>
      <td>A****</td>
      <td>35</td>
      <td></td>
    </tr>
    <tr>
      <td>11***</td>
      <td>AY**</td>
      <td>ZE***</td>
      <td>40</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AY***</td>
      <td>Ş****</td>
      <td>200</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>AYB**</td>
      <td>ZE***</td>
      <td>71</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AY***</td>
      <td>B****</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AY***</td>
      <td>C****</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>A****</td>
      <td>Ç****</td>
      <td>51</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AY***</td>
      <td>I****</td>
      <td>53</td>
      <td></td>
    </tr>
    <tr>
      <td>3****</td>
      <td>A****</td>
      <td>R****</td>
      <td>57</td>
      <td>7. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>AYD**</td>
      <td>ZE***</td>
      <td>71</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>11***</td>
      <td>AYD**</td>
      <td>ZE***</td>
      <td>51</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AY***</td>
      <td>G****</td>
      <td>64</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>AZ***</td>
      <td>D****</td>
      <td>40</td>
      <td></td>
    </tr>
    <tr>
      <td>12***</td>
      <td>BA***</td>
      <td>BE***</td>
      <td>79</td>
      <td>Faika Demiray</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>BAH**</td>
      <td>BE***</td>
      <td>41</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>B****</td>
      <td>H****</td>
      <td>79</td>
      <td>Faika Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>BA***</td>
      <td>EM***</td>
      <td>36</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>BA***</td>
      <td>EL***</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>B****</td>
      <td>T****</td>
      <td>88</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>4****</td>
      <td>B****</td>
      <td>Ş****</td>
      <td>95</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>BA***</td>
      <td>BU***</td>
      <td>64</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>BA***</td>
      <td>ER***</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>11***</td>
      <td>BA***</td>
      <td>FA***</td>
      <td>79</td>
      <td>Faika Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>BA***</td>
      <td>İ****</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>12***</td>
      <td>BA***</td>
      <td>FA***</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>BA***</td>
      <td>EB***</td>
      <td>32</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>BAY**</td>
      <td>BE***</td>
      <td>78</td>
      <td>Faika Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>BA***</td>
      <td>C****</td>
      <td>56</td>
      <td>7. Yurt</td>
    </tr>
    <tr>
      <td>3****</td>
      <td>B****</td>
      <td>B****</td>
      <td>56</td>
      <td>7. Yurt</td>
    </tr>
    <tr>
      <td>2****</td>
      <td>B****</td>
      <td>S****</td>
      <td>48</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>B****</td>
      <td>Y****</td>
      <td>200</td>
      <td>Faika Demiray</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>Bİ***</td>
      <td>ZE***</td>
      <td>36</td>
      <td></td>
    </tr>
    <tr>
      <td>114**</td>
      <td>BİL**</td>
      <td>ZEY**</td>
      <td>45</td>
      <td></td>
    </tr>
    <tr>
      <td>113**</td>
      <td>BİL**</td>
      <td>ZEY**</td>
      <td>51</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Bİ***</td>
      <td>İ****</td>
      <td>33</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>B****</td>
      <td>O****</td>
      <td>53</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>BO***</td>
      <td>E****</td>
      <td>42</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>B****</td>
      <td>Ş****</td>
      <td>30</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>BO***</td>
      <td>B****</td>
      <td>46</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>B****</td>
      <td>A****</td>
      <td>57</td>
      <td>7. Yurt</td>
    </tr>
    <tr>
      <td>2****</td>
      <td>B****</td>
      <td>D****</td>
      <td>59</td>
      <td>7. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>BÜ***</td>
      <td>C****</td>
      <td>72</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>4****</td>
      <td>B****</td>
      <td>N****</td>
      <td>200</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>BÜ***</td>
      <td>İ****</td>
      <td>65</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>CA***</td>
      <td>Dİ***</td>
      <td>37</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>CA***</td>
      <td>A****</td>
      <td>59</td>
      <td>7. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>CA***</td>
      <td>C****</td>
      <td>64</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>CA***</td>
      <td>DA***</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>C****</td>
      <td>G****</td>
      <td>49</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>CA***</td>
      <td>H****</td>
      <td>70</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>2****</td>
      <td>C****</td>
      <td>E****</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>3****</td>
      <td>C****</td>
      <td>H****</td>
      <td>61</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>C****</td>
      <td>F****</td>
      <td>75</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Cİ***</td>
      <td>A****</td>
      <td>50</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Cİ***</td>
      <td>C****</td>
      <td>40</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Cİ***</td>
      <td>E****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>C****</td>
      <td>B****</td>
      <td>54</td>
      <td>7. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>CO***</td>
      <td>D****</td>
      <td>200</td>
      <td>Faika Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>CO***</td>
      <td>E****</td>
      <td>49</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>CO***</td>
      <td>H****</td>
      <td>57</td>
      <td>7. Yurt</td>
    </tr>
    <tr>
      <td>11***</td>
      <td>ÇA***</td>
      <td>AY***</td>
      <td>62</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ç****</td>
      <td>E****</td>
      <td>46</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ç****</td>
      <td>M****</td>
      <td>37</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÇA***</td>
      <td>S****</td>
      <td>33</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ç****</td>
      <td>İ****</td>
      <td>32</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ç****</td>
      <td>Ö****</td>
      <td>40</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ÇAN**</td>
      <td>BER**</td>
      <td>88</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ÇAN**</td>
      <td>BET**</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ÇA***</td>
      <td>AY***</td>
      <td>62</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ç****</td>
      <td>T****</td>
      <td>74</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ç****</td>
      <td>C****</td>
      <td>36</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ÇE***</td>
      <td>YA***</td>
      <td>38</td>
      <td></td>
    </tr>
    <tr>
      <td>1016*</td>
      <td>ÇEKİ*</td>
      <td>SUDE*</td>
      <td>81</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1011*</td>
      <td>ÇEKİ*</td>
      <td>SUDE*</td>
      <td>60</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÇE***</td>
      <td>AH***</td>
      <td>40</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÇE***</td>
      <td>AZ***</td>
      <td>29</td>
      <td></td>
    </tr>
    <tr>
      <td>11***</td>
      <td>ÇE***</td>
      <td>YA***</td>
      <td>200</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ç****</td>
      <td>Z****</td>
      <td>48</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ç****</td>
      <td>N****</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ç****</td>
      <td>D****</td>
      <td>18</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Çİ***</td>
      <td>A****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÇO***</td>
      <td>B****</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÇÜ***</td>
      <td>A****</td>
      <td>35</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>DAĞ**</td>
      <td>DA***</td>
      <td>61</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>DA***</td>
      <td>B****</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>DAL**</td>
      <td>DA***</td>
      <td>31</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>DA***</td>
      <td>C****</td>
      <td>22</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>DA***</td>
      <td>DU***</td>
      <td>28</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>DA***</td>
      <td>E****</td>
      <td>46</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>D****</td>
      <td>M****</td>
      <td>71</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>2****</td>
      <td>D****</td>
      <td>İ****</td>
      <td>54</td>
      <td>7. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>DE***</td>
      <td>NE***</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>DE***</td>
      <td>İ****</td>
      <td>70</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>DE***</td>
      <td>NU***</td>
      <td>60</td>
      <td>7. Yurt</td>
    </tr>
    <tr>
      <td>12***</td>
      <td>DE***</td>
      <td>EY***</td>
      <td>52</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>DE***</td>
      <td>EL***</td>
      <td>75</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>D****</td>
      <td>G****</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>DE***</td>
      <td>SE***</td>
      <td>75</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>DE***</td>
      <td>HA***</td>
      <td>41</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>DE***</td>
      <td>SU***</td>
      <td>87</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>DE***</td>
      <td>SI***</td>
      <td>78</td>
      <td>Faika Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>DE***</td>
      <td>C****</td>
      <td>200</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>D****</td>
      <td>Z****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>19***</td>
      <td>DE***</td>
      <td>SU***</td>
      <td>56</td>
      <td>7. Yurt</td>
    </tr>
    <tr>
      <td>18***</td>
      <td>DE***</td>
      <td>HA***</td>
      <td>74</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>DE***</td>
      <td>B****</td>
      <td>81</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>DE***</td>
      <td>EY***</td>
      <td>29</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Dİ***</td>
      <td>E****</td>
      <td>84</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>DO***</td>
      <td>H****</td>
      <td>200</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>DO***</td>
      <td>İ****</td>
      <td>74</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>DO***</td>
      <td>S****</td>
      <td>28</td>
      <td></td>
    </tr>
    <tr>
      <td>2****</td>
      <td>D****</td>
      <td>E****</td>
      <td>66</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>DU***</td>
      <td>C****</td>
      <td>82</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>DU***</td>
      <td>EC***</td>
      <td>58</td>
      <td>7. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>DU***</td>
      <td>EM***</td>
      <td>69</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>DU***</td>
      <td>B****</td>
      <td>68</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>DÜ***</td>
      <td>C****</td>
      <td>200</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>DÜ***</td>
      <td>S****</td>
      <td>37</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>D****</td>
      <td>A****</td>
      <td>29</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>EG***</td>
      <td>T****</td>
      <td>72</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>EK***</td>
      <td>A****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>E****</td>
      <td>Y****</td>
      <td>42</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>EK***</td>
      <td>E****</td>
      <td>78</td>
      <td>Faika Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>EL***</td>
      <td>DE***</td>
      <td>48</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>EL***</td>
      <td>T****</td>
      <td>36</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>E****</td>
      <td>C****</td>
      <td>50</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>EL***</td>
      <td>B****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>EL***</td>
      <td>DO***</td>
      <td>42</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>EM***</td>
      <td>N****</td>
      <td>72</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>EN***</td>
      <td>Ş****</td>
      <td>200</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ER***</td>
      <td>M****</td>
      <td>55</td>
      <td>7. Yurt</td>
    </tr>
    <tr>
      <td>12***</td>
      <td>ER***</td>
      <td>IR***</td>
      <td>66</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ERC**</td>
      <td>NE***</td>
      <td>68</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ER***</td>
      <td>DE***</td>
      <td>55</td>
      <td>7. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ER***</td>
      <td>AL***</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ERD**</td>
      <td>NE***</td>
      <td>74</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ER***</td>
      <td>AS***</td>
      <td>200</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ER***</td>
      <td>AY***</td>
      <td>63</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ER***</td>
      <td>EL***</td>
      <td>41</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ER***</td>
      <td>IR***</td>
      <td>40</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ER***</td>
      <td>İP***</td>
      <td>200</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ERE**</td>
      <td>GÜ***</td>
      <td>24</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>E****</td>
      <td>K****</td>
      <td>50</td>
      <td></td>
    </tr>
    <tr>
      <td>13***</td>
      <td>ER***</td>
      <td>ZE***</td>
      <td>78</td>
      <td>Faika Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ER***</td>
      <td>EK***</td>
      <td>33</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ER***</td>
      <td>Ş****</td>
      <td>200</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>E****</td>
      <td>R****</td>
      <td>37</td>
      <td></td>
    </tr>
    <tr>
      <td>11***</td>
      <td>ER***</td>
      <td>EY***</td>
      <td>63</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ERK**</td>
      <td>İN***</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ER***</td>
      <td>ZE***</td>
      <td>56</td>
      <td>7. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ER***</td>
      <td>B****</td>
      <td>63</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ERO**</td>
      <td>İN***</td>
      <td>34</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ER***</td>
      <td>DU***</td>
      <td>33</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ER***</td>
      <td>EY***</td>
      <td>45</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>E****</td>
      <td>H****</td>
      <td>77</td>
      <td>Faika Demiray</td>
    </tr>
    <tr>
      <td>11***</td>
      <td>ER***</td>
      <td>IR***</td>
      <td>64</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ER***</td>
      <td>EZ***</td>
      <td>200</td>
      <td>7. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>E****</td>
      <td>S****</td>
      <td>30</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ERT**</td>
      <td>GÜ***</td>
      <td>75</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ER***</td>
      <td>T****</td>
      <td>64</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ES***</td>
      <td>B****</td>
      <td>36</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ES***</td>
      <td>M****</td>
      <td>81</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>EV***</td>
      <td>Z****</td>
      <td>62</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>EV***</td>
      <td>M****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>EV***</td>
      <td>N****</td>
      <td>66</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>GE***</td>
      <td>D****</td>
      <td>86</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>G****</td>
      <td>P****</td>
      <td>33</td>
      <td></td>
    </tr>
    <tr>
      <td>7****</td>
      <td>G****</td>
      <td>M****</td>
      <td>81</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>G****</td>
      <td>Z****</td>
      <td>37</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>GE***</td>
      <td>E****</td>
      <td>33</td>
      <td></td>
    </tr>
    <tr>
      <td bgcolor="yellow">
        <b>3****</b>
      </td>
      <td bgcolor="yellow">
        <b>G****</b>
      </td>
      <td bgcolor="yellow">
        <b>B****</b>
      </td>
      <td bgcolor="yellow">
        <b>200</b>
      </td>
      <td bgcolor="yellow">
        <b>19. Yurt</b>
      </td>
    </tr>
    <tr>
      <td>1****</td>
      <td>GÖ***</td>
      <td>İZ***</td>
      <td>44</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>GÖ***</td>
      <td>H****</td>
      <td>85</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>GÖ***</td>
      <td>E****</td>
      <td>48</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>GÖ***</td>
      <td>C****</td>
      <td>65</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>GÖ***</td>
      <td>İR***</td>
      <td>76</td>
      <td>Faika Demiray</td>
    </tr>
    <tr>
      <td>11***</td>
      <td>GÜL**</td>
      <td>SE***</td>
      <td>40</td>
      <td></td>
    </tr>
    <tr>
      <td>12***</td>
      <td>GÜ***</td>
      <td>SE***</td>
      <td>81</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>GÜL**</td>
      <td>DE***</td>
      <td>56</td>
      <td>7. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>GÜ***</td>
      <td>AD***</td>
      <td>51</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>GÜ***</td>
      <td>H****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>6****</td>
      <td>G****</td>
      <td>İ****</td>
      <td>48</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>G****</td>
      <td>B****</td>
      <td>63</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>3****</td>
      <td>G****</td>
      <td>E****</td>
      <td>78</td>
      <td>Faika Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>GÜ***</td>
      <td>AS***</td>
      <td>51</td>
      <td></td>
    </tr>
    <tr>
      <td>13***</td>
      <td>GÜ***</td>
      <td>CE***</td>
      <td>38</td>
      <td></td>
    </tr>
    <tr>
      <td>2****</td>
      <td>G****</td>
      <td>İ****</td>
      <td>72</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>11***</td>
      <td>GÜ***</td>
      <td>CE***</td>
      <td>48</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>G****</td>
      <td>N****</td>
      <td>59</td>
      <td>7. Yurt</td>
    </tr>
    <tr>
      <td>2****</td>
      <td>G****</td>
      <td>E****</td>
      <td>45</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>GÜ***</td>
      <td>SE***</td>
      <td>46</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>GÜ***</td>
      <td>E****</td>
      <td>69</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>GÜR**</td>
      <td>DE***</td>
      <td>34</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>G****</td>
      <td>F****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>11***</td>
      <td>GÜR**</td>
      <td>SE***</td>
      <td>40</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>HA***</td>
      <td>SI***</td>
      <td>40</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>H****</td>
      <td>A****</td>
      <td>22</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>HA***</td>
      <td>SE***</td>
      <td>68</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>H****</td>
      <td>E****</td>
      <td>56</td>
      <td>7. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>I****</td>
      <td>A****</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>I****</td>
      <td>F****</td>
      <td>48</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>İ****</td>
      <td>B****</td>
      <td>40</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>İ****</td>
      <td>D****</td>
      <td>33</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>İ****</td>
      <td>S****</td>
      <td>43</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>İ****</td>
      <td>M****</td>
      <td>37</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>İ****</td>
      <td>A****</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>İ****</td>
      <td>E****</td>
      <td>74</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>İ****</td>
      <td>N****</td>
      <td>36</td>
      <td></td>
    </tr>
    <tr>
      <td>11***</td>
      <td>KA***</td>
      <td>EL***</td>
      <td>51</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>KA***</td>
      <td>ME***</td>
      <td>74</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>11***</td>
      <td>KA***</td>
      <td>SU***</td>
      <td>37</td>
      <td></td>
    </tr>
    <tr>
      <td>12***</td>
      <td>KA***</td>
      <td>SU***</td>
      <td>65</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>2****</td>
      <td>KA***</td>
      <td>İ****</td>
      <td>70</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KA***</td>
      <td>ŞU***</td>
      <td>84</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KA***</td>
      <td>BA***</td>
      <td>79</td>
      <td>Faika Demiray</td>
    </tr>
    <tr>
      <td>11***</td>
      <td>KAN**</td>
      <td>ME***</td>
      <td>62</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>31***</td>
      <td>KA***</td>
      <td>KÜ***</td>
      <td>69</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>12***</td>
      <td>KA***</td>
      <td>EL***</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>11***</td>
      <td>KAP**</td>
      <td>ME***</td>
      <td>54</td>
      <td>7. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KA***</td>
      <td>EY***</td>
      <td>45</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KA***</td>
      <td>Nİ***</td>
      <td>19</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KA***</td>
      <td>Sİ***</td>
      <td>70</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>KAR**</td>
      <td>DER**</td>
      <td>63</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KA***</td>
      <td>K****</td>
      <td>74</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>KA***</td>
      <td>PE***</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>34***</td>
      <td>KA***</td>
      <td>KÜ***</td>
      <td>60</td>
      <td>7. Yurt</td>
    </tr>
    <tr>
      <td>2****</td>
      <td>KA***</td>
      <td>ER***</td>
      <td>36</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KA***</td>
      <td>RA***</td>
      <td>200</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>3****</td>
      <td>K****</td>
      <td>S****</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KA***</td>
      <td>Fİ***</td>
      <td>80</td>
      <td>Faika Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KA***</td>
      <td>SA***</td>
      <td>200</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KA***</td>
      <td>NE***</td>
      <td>38</td>
      <td></td>
    </tr>
    <tr>
      <td>11***</td>
      <td>KA***</td>
      <td>ZE***</td>
      <td>40</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KA***</td>
      <td>AS***</td>
      <td>64</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>KA***</td>
      <td>SU***</td>
      <td>40</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>KAR**</td>
      <td>DEN**</td>
      <td>61</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KA***</td>
      <td>EK***</td>
      <td>43</td>
      <td></td>
    </tr>
    <tr>
      <td>14***</td>
      <td>KA***</td>
      <td>PE***</td>
      <td>27</td>
      <td></td>
    </tr>
    <tr>
      <td>13***</td>
      <td>KA***</td>
      <td>ES***</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KA***</td>
      <td>ŞE***</td>
      <td>43</td>
      <td></td>
    </tr>
    <tr>
      <td>2****</td>
      <td>KA***</td>
      <td>ES***</td>
      <td>57</td>
      <td>7. Yurt</td>
    </tr>
    <tr>
      <td>12***</td>
      <td>KA***</td>
      <td>DE***</td>
      <td>41</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>KA***</td>
      <td>ES***</td>
      <td>44</td>
      <td></td>
    </tr>
    <tr>
      <td>11***</td>
      <td>KAŞ**</td>
      <td>BE***</td>
      <td>80</td>
      <td>Faika Demiray</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>KA***</td>
      <td>İR***</td>
      <td>30</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KA***</td>
      <td>RE***</td>
      <td>200</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>11***</td>
      <td>KAV**</td>
      <td>BE***</td>
      <td>49</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KA***</td>
      <td>T****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KA***</td>
      <td>Ö****</td>
      <td>45</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KA***</td>
      <td>AL***</td>
      <td>66</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>KA***</td>
      <td>BE***</td>
      <td>88</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>11***</td>
      <td>KAY**</td>
      <td>BE***</td>
      <td>58</td>
      <td>7. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KA***</td>
      <td>DA***</td>
      <td>78</td>
      <td>Faika Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KA***</td>
      <td>Dİ***</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KA***</td>
      <td>FA***</td>
      <td>81</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KA***</td>
      <td>SI***</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>16***</td>
      <td>KA***</td>
      <td>ZE***</td>
      <td>37</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>KA***</td>
      <td>EL***</td>
      <td>38</td>
      <td></td>
    </tr>
    <tr>
      <td>11***</td>
      <td>KA***</td>
      <td>İR***</td>
      <td>61</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KE***</td>
      <td>E****</td>
      <td>46</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KE***</td>
      <td>Ö****</td>
      <td>61</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KE***</td>
      <td>Z****</td>
      <td>61</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KE***</td>
      <td>Ş****</td>
      <td>76</td>
      <td>Faika Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KE***</td>
      <td>G****</td>
      <td>38</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>KIL**</td>
      <td>CE***</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KI***</td>
      <td>M****</td>
      <td>200</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KI***</td>
      <td>B****</td>
      <td>80</td>
      <td>Faika Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KI***</td>
      <td>D****</td>
      <td>200</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>KI***</td>
      <td>EL***</td>
      <td>50</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KI***</td>
      <td>Nİ***</td>
      <td>26</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>KIN**</td>
      <td>CE***</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KI***</td>
      <td>T****</td>
      <td>59</td>
      <td>7. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KI***</td>
      <td>Z****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KI***</td>
      <td>EC***</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>12***</td>
      <td>KI***</td>
      <td>EL***</td>
      <td>74</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>KIY**</td>
      <td>SI***</td>
      <td>67</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KI***</td>
      <td>NE***</td>
      <td>200</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>KIZ**</td>
      <td>SI***</td>
      <td>36</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Kİ***</td>
      <td>İ****</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>K****</td>
      <td>H****</td>
      <td>67</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KO***</td>
      <td>C****</td>
      <td>38</td>
      <td></td>
    </tr>
    <tr>
      <td>11***</td>
      <td>KO***</td>
      <td>BE***</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>KO***</td>
      <td>BE***</td>
      <td>200</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KO***</td>
      <td>D****</td>
      <td>27</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KO***</td>
      <td>E****</td>
      <td>35</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KO***</td>
      <td>G****</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KO***</td>
      <td>R****</td>
      <td>78</td>
      <td>Faika Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KO***</td>
      <td>K****</td>
      <td>67</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KO***</td>
      <td>Ö****</td>
      <td>29</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KÖ***</td>
      <td>A****</td>
      <td>60</td>
      <td>7. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KÖ***</td>
      <td>EL***</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KÖ***</td>
      <td>B****</td>
      <td>200</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KÖ***</td>
      <td>EY***</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>2****</td>
      <td>KÖ***</td>
      <td>İ****</td>
      <td>60</td>
      <td>7. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KÖ***</td>
      <td>N****</td>
      <td>66</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KÖ***</td>
      <td>S****</td>
      <td>200</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KU***</td>
      <td>B****</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KU***</td>
      <td>S****</td>
      <td>66</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KU***</td>
      <td>G****</td>
      <td>50</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KU***</td>
      <td>İ****</td>
      <td>53</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KU***</td>
      <td>EL***</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KU***</td>
      <td>M****</td>
      <td>79</td>
      <td>Faika Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KU***</td>
      <td>A****</td>
      <td>18</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KU***</td>
      <td>EC***</td>
      <td>18</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KU***</td>
      <td>T****</td>
      <td>56</td>
      <td>7. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>K****</td>
      <td>Y****</td>
      <td>38</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>KÜ***</td>
      <td>E****</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>L****</td>
      <td>T****</td>
      <td>38</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>L****</td>
      <td>S****</td>
      <td>38</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>M****</td>
      <td>G****</td>
      <td>61</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ME***</td>
      <td>A****</td>
      <td>36</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ME***</td>
      <td>E****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>M****</td>
      <td>D****</td>
      <td>26</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Mİ***</td>
      <td>A****</td>
      <td>66</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>M****</td>
      <td>Y****</td>
      <td>33</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>M****</td>
      <td>Ş****</td>
      <td>200</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>MU***</td>
      <td>A****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>M****</td>
      <td>B****</td>
      <td>38</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>MU***</td>
      <td>E****</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>M****</td>
      <td>Z****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>M****</td>
      <td>N****</td>
      <td>73</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>N****</td>
      <td>E****</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>2****</td>
      <td>O****</td>
      <td>Y****</td>
      <td>40</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>OĞ***</td>
      <td>E****</td>
      <td>46</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>O****</td>
      <td>S****</td>
      <td>67</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>O****</td>
      <td>B****</td>
      <td>40</td>
      <td></td>
    </tr>
    <tr>
      <td>2****</td>
      <td>O****</td>
      <td>D****</td>
      <td>79</td>
      <td>Faika Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>O****</td>
      <td>İ****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>O****</td>
      <td>Ş****</td>
      <td>49</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>OR***</td>
      <td>E****</td>
      <td>58</td>
      <td>7. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>O****</td>
      <td>P****</td>
      <td>200</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÖC***</td>
      <td>Ö****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÖĞ***</td>
      <td>E****</td>
      <td>37</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÖĞ***</td>
      <td>İ****</td>
      <td>32</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ö****</td>
      <td>Y****</td>
      <td>89</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÖK***</td>
      <td>S****</td>
      <td>43</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÖL***</td>
      <td>D****</td>
      <td>66</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÖN***</td>
      <td>N****</td>
      <td>53</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ö****</td>
      <td>F****</td>
      <td>86</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ÖZ***</td>
      <td>NA***</td>
      <td>38</td>
      <td></td>
    </tr>
    <tr>
      <td>12***</td>
      <td>ÖZ***</td>
      <td>IR***</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>2****</td>
      <td>ÖZ***</td>
      <td>SE***</td>
      <td>38</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÖZ***</td>
      <td>AL***</td>
      <td>82</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ö****</td>
      <td>B****</td>
      <td>200</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÖZ***</td>
      <td>EL***</td>
      <td>78</td>
      <td>Faika Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ö****</td>
      <td>R****</td>
      <td>40</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÖZ***</td>
      <td>SE***</td>
      <td>50</td>
      <td></td>
    </tr>
    <tr>
      <td>13***</td>
      <td>ÖZ***</td>
      <td>SU***</td>
      <td>60</td>
      <td>7. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ö****</td>
      <td>H****</td>
      <td>72</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>12***</td>
      <td>ÖZ***</td>
      <td>İR***</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ÖZD**</td>
      <td>MİN**</td>
      <td>79</td>
      <td>Faika Demiray</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ÖZD**</td>
      <td>MİR**</td>
      <td>77</td>
      <td>Faika Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÖZ***</td>
      <td>EY***</td>
      <td>69</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ö****</td>
      <td>P****</td>
      <td>81</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ö****</td>
      <td>Ş****</td>
      <td>200</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÖZ***</td>
      <td>DU***</td>
      <td>48</td>
      <td></td>
    </tr>
    <tr>
      <td>11***</td>
      <td>ÖZE**</td>
      <td>ÖZ***</td>
      <td>50</td>
      <td></td>
    </tr>
    <tr>
      <td>11***</td>
      <td>ÖZ***</td>
      <td>İR***</td>
      <td>33</td>
      <td></td>
    </tr>
    <tr>
      <td>13***</td>
      <td>ÖZ***</td>
      <td>AY***</td>
      <td>43</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÖZ***</td>
      <td>İP***</td>
      <td>38</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ÖZ***</td>
      <td>ME***</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ÖZ***</td>
      <td>SU***</td>
      <td>67</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ö****</td>
      <td>Z****</td>
      <td>55</td>
      <td>7. Yurt</td>
    </tr>
    <tr>
      <td>7****</td>
      <td>Ö****</td>
      <td>S****</td>
      <td>7</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ÖZK**</td>
      <td>AY***</td>
      <td>64</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ÖZ***</td>
      <td>IR***</td>
      <td>200</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ÖZT**</td>
      <td>AYL**</td>
      <td>37</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ÖZT**</td>
      <td>AYŞ**</td>
      <td>49</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÖZ***</td>
      <td>DO***</td>
      <td>57</td>
      <td>7. Yurt</td>
    </tr>
    <tr>
      <td>11***</td>
      <td>ÖZ***</td>
      <td>IR***</td>
      <td>36</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ÖZ***</td>
      <td>İR***</td>
      <td>56</td>
      <td>7. Yurt</td>
    </tr>
    <tr>
      <td>12***</td>
      <td>ÖZ***</td>
      <td>ME***</td>
      <td>80</td>
      <td>Faika Demiray</td>
    </tr>
    <tr>
      <td>13***</td>
      <td>ÖZ***</td>
      <td>NA***</td>
      <td>45</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÖZ***</td>
      <td>NU***</td>
      <td>37</td>
      <td></td>
    </tr>
    <tr>
      <td>11***</td>
      <td>ÖZT**</td>
      <td>ÖZ***</td>
      <td>37</td>
      <td></td>
    </tr>
    <tr>
      <td>2****</td>
      <td>Ö****</td>
      <td>R****</td>
      <td>38</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÖZ***</td>
      <td>SA***</td>
      <td>68</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>2****</td>
      <td>ÖZ***</td>
      <td>SI***</td>
      <td>64</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ÖZ***</td>
      <td>Dİ***</td>
      <td>78</td>
      <td>Faika Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>PA***</td>
      <td>E****</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>P****</td>
      <td>R****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>PA***</td>
      <td>G****</td>
      <td>60</td>
      <td>7. Yurt</td>
    </tr>
    <tr>
      <td>4****</td>
      <td>P****</td>
      <td>B****</td>
      <td>65</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>P****</td>
      <td>N****</td>
      <td>53</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>P****</td>
      <td>K****</td>
      <td>76</td>
      <td>Faika Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Pİ***</td>
      <td>F****</td>
      <td>64</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>PO***</td>
      <td>F****</td>
      <td>91</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>PO***</td>
      <td>G****</td>
      <td>90</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>PO***</td>
      <td>E****</td>
      <td>98</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>R****</td>
      <td>D****</td>
      <td>200</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>SA***</td>
      <td>ZE***</td>
      <td>67</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>11***</td>
      <td>SA***</td>
      <td>ZE***</td>
      <td>31</td>
      <td></td>
    </tr>
    <tr>
      <td>18***</td>
      <td>SA***</td>
      <td>ZE***</td>
      <td>38</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>S****</td>
      <td>A****</td>
      <td>40</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>SA***</td>
      <td>ZÜ***</td>
      <td>64</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>SA***</td>
      <td>B****</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>SA***</td>
      <td>G****</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>S****</td>
      <td>H****</td>
      <td>23</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>SA***</td>
      <td>E****</td>
      <td>61</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>17***</td>
      <td>SA***</td>
      <td>SE***</td>
      <td>66</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>S****</td>
      <td>Ş****</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>S****</td>
      <td>Ç****</td>
      <td>28</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>SA***</td>
      <td>SE***</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>2****</td>
      <td>S****</td>
      <td>G****</td>
      <td>78</td>
      <td>Faika Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>SE***</td>
      <td>İL***</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>11***</td>
      <td>SER**</td>
      <td>SU***</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>SE***</td>
      <td>İD***</td>
      <td>32</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>SE***</td>
      <td>M****</td>
      <td>68</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>S****</td>
      <td>R****</td>
      <td>66</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>SE***</td>
      <td>B****</td>
      <td>49</td>
      <td></td>
    </tr>
    <tr>
      <td>11***</td>
      <td>SEV**</td>
      <td>SU***</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>SE***</td>
      <td>E****</td>
      <td>70</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>S****</td>
      <td>F****</td>
      <td>71</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>2****</td>
      <td>S****</td>
      <td>Ç****</td>
      <td>60</td>
      <td>7. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>SI***</td>
      <td>E****</td>
      <td>38</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>S****</td>
      <td>L****</td>
      <td>41</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>SI***</td>
      <td>Z****</td>
      <td>81</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>SO***</td>
      <td>EC***</td>
      <td>30</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>SOL**</td>
      <td>EL***</td>
      <td>67</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>SO***</td>
      <td>M****</td>
      <td>81</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>S****</td>
      <td>N****</td>
      <td>78</td>
      <td>Faika Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>SO***</td>
      <td>Z****</td>
      <td>36</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>SOY**</td>
      <td>EL***</td>
      <td>42</td>
      <td></td>
    </tr>
    <tr>
      <td>12***</td>
      <td>SO***</td>
      <td>GÜ***</td>
      <td>65</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>18***</td>
      <td>SO***</td>
      <td>GÜ***</td>
      <td>45</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>S****</td>
      <td>Y****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>3****</td>
      <td>Ş****</td>
      <td>S****</td>
      <td>40</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ŞA***</td>
      <td>H****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ŞA***</td>
      <td>İ****</td>
      <td>38</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ş****</td>
      <td>K****</td>
      <td>89</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>2****</td>
      <td>Ş****</td>
      <td>M****</td>
      <td>76</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ş****</td>
      <td>R****</td>
      <td>64</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ş****</td>
      <td>Z****</td>
      <td>78</td>
      <td>Faika Demiray</td>
    </tr>
    <tr>
      <td>3****</td>
      <td>Ş****</td>
      <td>L****</td>
      <td>90</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ŞE***</td>
      <td>EM***</td>
      <td>200</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ŞE***</td>
      <td>EB***</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ŞE***</td>
      <td>İP***</td>
      <td>21</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ŞE***</td>
      <td>İR***</td>
      <td>43</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ş****</td>
      <td>S****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ŞE***</td>
      <td>H****</td>
      <td>70</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>ŞE***</td>
      <td>EF***</td>
      <td>33</td>
      <td></td>
    </tr>
    <tr>
      <td>2****</td>
      <td>Ş****</td>
      <td>E****</td>
      <td>48</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ŞİM**</td>
      <td>BE***</td>
      <td>69</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Şİ***</td>
      <td>İ****</td>
      <td>49</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>ŞİR**</td>
      <td>BE***</td>
      <td>23</td>
      <td></td>
    </tr>
    <tr>
      <td>11***</td>
      <td>TAÇ**</td>
      <td>ZE***</td>
      <td>64</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TA***</td>
      <td>EC***</td>
      <td>53</td>
      <td></td>
    </tr>
    <tr>
      <td>11***</td>
      <td>TA***</td>
      <td>EL***</td>
      <td>65</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TA***</td>
      <td>Nİ***</td>
      <td>89</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TA***</td>
      <td>NE***</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>T****</td>
      <td>D****</td>
      <td>41</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TA***</td>
      <td>S****</td>
      <td>37</td>
      <td></td>
    </tr>
    <tr>
      <td>11***</td>
      <td>TAR**</td>
      <td>ZE***</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>TA***</td>
      <td>ZE***</td>
      <td>46</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>TA***</td>
      <td>EL***</td>
      <td>64</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>T****</td>
      <td>F****</td>
      <td>60</td>
      <td>7. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TE***</td>
      <td>NE***</td>
      <td>69</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TE***</td>
      <td>Nİ***</td>
      <td>78</td>
      <td>Faika Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>T****</td>
      <td>G****</td>
      <td>200</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>T****</td>
      <td>Ş****</td>
      <td>32</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TE***</td>
      <td>NU***</td>
      <td>70</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TE***</td>
      <td>C****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TE***</td>
      <td>S****</td>
      <td>77</td>
      <td>Faika Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>T****</td>
      <td>T****</td>
      <td>34</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TI***</td>
      <td>B****</td>
      <td>59</td>
      <td>7. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Tİ***</td>
      <td>E****</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TO***</td>
      <td>C****</td>
      <td>69</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>10***</td>
      <td>TO***</td>
      <td>ZE***</td>
      <td>63</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TO***</td>
      <td>S****</td>
      <td>52</td>
      <td></td>
    </tr>
    <tr>
      <td>11***</td>
      <td>TO***</td>
      <td>ZE***</td>
      <td>88</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TU***</td>
      <td>E****</td>
      <td>78</td>
      <td>Faika Demiray</td>
    </tr>
    <tr>
      <td>3****</td>
      <td>TU***</td>
      <td>A****</td>
      <td>37</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TU***</td>
      <td>C****</td>
      <td>61</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>T****</td>
      <td>Y****</td>
      <td>200</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TÜ***</td>
      <td>B****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TÜ***</td>
      <td>S****</td>
      <td>23</td>
      <td></td>
    </tr>
    <tr>
      <td>3****</td>
      <td>TÜ***</td>
      <td>A****</td>
      <td>85</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>TÜ***</td>
      <td>Z****</td>
      <td>50</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>U****</td>
      <td>A****</td>
      <td>48</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>U****</td>
      <td>L****</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>UĞ***</td>
      <td>S****</td>
      <td>72</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>3****</td>
      <td>U****</td>
      <td>İ****</td>
      <td>38</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>UL***</td>
      <td>S****</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>UL***</td>
      <td>E****</td>
      <td>33</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>U****</td>
      <td>M****</td>
      <td>37</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>U****</td>
      <td>D****</td>
      <td>27</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>UY***</td>
      <td>E****</td>
      <td>51</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>U****</td>
      <td>Y****</td>
      <td>52</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ü****</td>
      <td>S****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ü****</td>
      <td>Ö****</td>
      <td>80</td>
      <td>Faika Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Ü****</td>
      <td>E****</td>
      <td>200</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>V****</td>
      <td>Z****</td>
      <td>65</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>V****</td>
      <td>C****</td>
      <td>87</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>VI***</td>
      <td>S****</td>
      <td>59</td>
      <td>7. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>V****</td>
      <td>E****</td>
      <td>49</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>VU***</td>
      <td>S****</td>
      <td>74</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YA***</td>
      <td>T****</td>
      <td>33</td>
      <td></td>
    </tr>
    <tr>
      <td>11***</td>
      <td>YAL**</td>
      <td>DE***</td>
      <td>52</td>
      <td></td>
    </tr>
    <tr>
      <td>12***</td>
      <td>YA***</td>
      <td>BE***</td>
      <td>42</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Y****</td>
      <td>P****</td>
      <td>200</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YA***</td>
      <td>NE***</td>
      <td>200</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YA***</td>
      <td>Dİ***</td>
      <td>68</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Y****</td>
      <td>M****</td>
      <td>77</td>
      <td>Faika Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YA***</td>
      <td>S****</td>
      <td>46</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YA***</td>
      <td>NA***</td>
      <td>78</td>
      <td>Faika Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YA***</td>
      <td>Nİ***</td>
      <td>41</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YA***</td>
      <td>Ö****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>2****</td>
      <td>YA***</td>
      <td>Z****</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>11***</td>
      <td>YAV**</td>
      <td>DE***</td>
      <td>22</td>
      <td></td>
    </tr>
    <tr>
      <td>10***</td>
      <td>YA***</td>
      <td>BE***</td>
      <td>20</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YA***</td>
      <td>G****</td>
      <td>37</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Y****</td>
      <td>İ****</td>
      <td>64</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YE***</td>
      <td>SE***</td>
      <td>54</td>
      <td>7. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YE***</td>
      <td>SI***</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YI***</td>
      <td>AS***</td>
      <td>77</td>
      <td>Faika Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YI***</td>
      <td>AY***</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Y****</td>
      <td>C****</td>
      <td>80</td>
      <td>Faika Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YI***</td>
      <td>EY***</td>
      <td>37</td>
      <td></td>
    </tr>
    <tr>
      <td>4****</td>
      <td>Y****</td>
      <td>Ö****</td>
      <td>53</td>
      <td></td>
    </tr>
    <tr>
      <td>6****</td>
      <td>Y****</td>
      <td>S****</td>
      <td>44</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YI***</td>
      <td>SU***</td>
      <td>38</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YI***</td>
      <td>Z****</td>
      <td>200</td>
      <td>Faika Demiray</td>
    </tr>
    <tr>
      <td>2****</td>
      <td>Y****</td>
      <td>A****</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YI***</td>
      <td>B****</td>
      <td>200</td>
      <td>Faika Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YI***</td>
      <td>EL***</td>
      <td>67</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YI***</td>
      <td>ES***</td>
      <td>46</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YI***</td>
      <td>HÜ***</td>
      <td>96</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YI***</td>
      <td>L****</td>
      <td>43</td>
      <td></td>
    </tr>
    <tr>
      <td>2****</td>
      <td>Y****</td>
      <td>N****</td>
      <td>37</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YI***</td>
      <td>SE***</td>
      <td>66</td>
      <td>5. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YI***</td>
      <td>AD***</td>
      <td>35</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YI***</td>
      <td>D****</td>
      <td>37</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YI***</td>
      <td>EC***</td>
      <td>90</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YI***</td>
      <td>EK***</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Y****</td>
      <td>F****</td>
      <td>200</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YI***</td>
      <td>HA***</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>3****</td>
      <td>Y****</td>
      <td>N****</td>
      <td>38</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YI***</td>
      <td>N****</td>
      <td>51</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YI***</td>
      <td>SI***</td>
      <td>56</td>
      <td>7. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YI***</td>
      <td>SÜ***</td>
      <td>40</td>
      <td></td>
    </tr>
    <tr>
      <td>2****</td>
      <td>YI***</td>
      <td>Z****</td>
      <td>46</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YI***</td>
      <td>T****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Yİ***</td>
      <td>E****</td>
      <td>34</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YO***</td>
      <td>L****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YO***</td>
      <td>N****</td>
      <td>39</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YO***</td>
      <td>Ö****</td>
      <td>28</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YÖ***</td>
      <td>G****</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Y****</td>
      <td>Y****</td>
      <td>87</td>
      <td>1. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YU***</td>
      <td>B****</td>
      <td>36</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YU***</td>
      <td>E****</td>
      <td>67</td>
      <td>3. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Y****</td>
      <td>R****</td>
      <td>79</td>
      <td>Faika Demiray</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YÜ***</td>
      <td>S****</td>
      <td>32</td>
      <td></td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YÜ***</td>
      <td>Z****</td>
      <td>74</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>YÜ***</td>
      <td>Ö****</td>
      <td>200</td>
      <td>Refika Aksoy</td>
    </tr>
    <tr>
      <td>5****</td>
      <td>Z****</td>
      <td>A****</td>
      <td>200</td>
      <td>19. Yurt</td>
    </tr>
    <tr>
      <td>1****</td>
      <td>Z****</td>
      <td>S****</td>
      <td>36</td>
      <td></td>
    </tr>
  </tbody>
</table>

</body>
</html>
`;

