import React from 'react';

import './Blog.css'


const Blog = () => {

  return (
      <>
          <div>
              Blog
        </div>
      </>
  )
}

export default Blog
