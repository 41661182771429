export const skillsContent = {
        file: "skills",
        cat: 
        `#!/bin/bash 

skills=( 
    "docker" 
    "python" 
    "bash" 
    "linux" 
    "kubernetes"
    "graphql"
    "JS"
    "golang"
    "git"
    "sql"
)



















e`
};

export const aboutContent = {
        file: "about",
        cat:
        `#!/bin/bash 

`
};


export const expContent = {
        file: "experience",
        cat:
        `#!/bin/bash 

# triggerz
# ehe
# mühe`
};

export const projectsContent = {
        file: "projects",
        cat:
        `#!/bin/bash 

# asdlakd ş
# asoşdaskşd 
# asşdaskdlşk`
};
